import { styled } from "@mui/material/styles";
import Switch, { switchClasses } from "@mui/material/Switch";

const pxToRem = (px: number, oneRemPx: number = 17): number => px / oneRemPx;

export const StyledSwitch = styled(Switch)(({ theme }) => {
  const borderWidth = 2;
  const width = pxToRem(36);
  const height = pxToRem(18);
  const size = pxToRem(16);
  const gap = (height - size) / 2;
  const primaryColor = theme.palette.primary.main;
  return {
    width: `${width}rem`,
    height: `${height}rem`,
    padding: 0,
    margin: theme.spacing(1),
    overflow: "unset",
    [`& .${switchClasses.switchBase}`]: {
      padding: "3px",
      [`&.${switchClasses.checked}`]: {
        color: "#fff",
        transform: `translateX(calc(${width}rem - ${size}rem - ${pxToRem(
          2 * gap,
        )}rem))`,
        [`& + .${switchClasses.track}`]: {
          backgroundColor: theme.palette.grey[900],
          opacity: 1,
        },
        [`& .${switchClasses.thumb}`]: {
          backgroundColor: "#fff",
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="${encodeURIComponent(
            primaryColor,
          )}" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>')`,
          backgroundSize: "90%",
        },
      },
    },
    [`& .${switchClasses.disabled}`]: {
      [`& .${switchClasses.thumb}`]: {
        color: "#bdbdbd",
      },
      [`& + .${switchClasses.track}`]: {
        backgroundColor: "#000",
      },
    },
    [`& .${switchClasses.track}`]: {
      borderRadius: 40,
      backgroundColor: theme.palette.grey[600],
      border: "solid transparent",
      borderWidth,
      opacity: 1,
    },
    [`& .${switchClasses.thumb}`]: {
      width: `${size}rem`,
      height: `${size}rem`,
      boxShadow: "none",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path fill="${encodeURIComponent(
        theme.palette.grey[600],
      )}" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "90%",
    },
  };
});
