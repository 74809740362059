import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  bookmarkBlogAsync,
  copyBlogAsync,
  getBlogAsync,
  getMoreBlogsAsync,
  getUserBlogBookmarksAsync,
  upvoteBlogAsync,
} from "../services/blogsService";
import { handleSnackbarOpen } from "../features/snackbarSlice";
import { AxiosResponse } from "axios";
import { RootState } from "../store";

// get individual blog
export const getBlogAction = createAsyncThunk(
  "blogs/getBlogs",
  async ({ blogId }: { blogId: string }, thunkAPI) => {
    try {
      const response: AxiosResponse = await getBlogAsync(blogId);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: "Cant retrieve blog" });
    }
  },
);

// get array of blogs publicly available
export const getMoreBlogsAction = createAsyncThunk(
  "blogs/getMoreBlogs",
  async ({ pageNumber }: { pageNumber: Number }, thunkAPI) => {
    try {
      const response: AxiosResponse = await getMoreBlogsAsync(pageNumber);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: "Cant retrieve blogs" });
    }
  },
);

export const upvoteBlogAction = createAsyncThunk(
  "blogs/upvoteBlog",
  async ({ blogId }: { blogId: string }, thunkAPI) => {
    try {
      const response: AxiosResponse = await upvoteBlogAsync(blogId);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: "Cant upvote blog" });
    }
  },
);

// bookmark a particular blog
// call the same action if bookmark need to be removed from the particular blog
export const bookmarkBlogAction = createAsyncThunk(
  "blogs/bookmarkBlog",
  async ({ blogId, userId }: { blogId: string; userId: string }, thunkAPI) => {
    try {
      const appState = thunkAPI.getState() as RootState;
      const { bookmarkedPageNumber, userBookmarkBlogs } = appState.blogs;
      const response: AxiosResponse = await bookmarkBlogAsync(blogId);
      if (response.status === 200) {
        const isAdded = response.data.message.includes("removed")
          ? false
          : true;

        if (isAdded) {
          thunkAPI.dispatch(
            handleSnackbarOpen({ message: "Bookmarked!", severity: "success" }),
          );
          thunkAPI.dispatch(
            getBookmarkedBlogAction({ pageNumber: bookmarkedPageNumber }),
          );
        } else {
          thunkAPI.dispatch(
            handleSnackbarOpen({
              message: "Bookmark removed!",
              severity: "success",
            }),
          );
          // if we are removing a bookmark and its the last one in that page,
          // loading that page will show a "no blog found" message
          // so we are loading the page previous to current one, and if its -ve page number, load the 0th page.
          // which will result in the "no blog found" message in UI.
          if (userBookmarkBlogs.length === 1) {
            const validPageNumber =
              bookmarkedPageNumber - 1 > 0 ? bookmarkedPageNumber - 1 : 0;
            thunkAPI.dispatch(
              getBookmarkedBlogAction({ pageNumber: validPageNumber }),
            );
          } else {
            thunkAPI.dispatch(
              getBookmarkedBlogAction({ pageNumber: bookmarkedPageNumber }),
            );
          }
        }

        return thunkAPI.fulfillWithValue({
          blogId: blogId,
          userId: userId,
          isAdded: isAdded,
        });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: "Cant bookmark blog" });
    }
  },
);

export const copyBlogAction = createAsyncThunk(
  "blogs/copyBlog",
  async ({ filePath }: { filePath: string }, thunkAPI) => {
    try {
      const response: AxiosResponse = await copyBlogAsync(filePath);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: "Cant bookmark blog" });
    }
  },
);

// get all user's bookmarked blogs
export const getBookmarkedBlogAction = createAsyncThunk(
  "blogs/getBookmarkedBlog",
  async ({ pageNumber }: { pageNumber: Number }, thunkAPI) => {
    try {
      const response: AxiosResponse =
        await getUserBlogBookmarksAsync(pageNumber);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Cant retrieve bookmarked blogs",
      });
    }
  },
);
