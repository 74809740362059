import React from "react";
import { Typography, Modal, Box, Paper, Button } from "@mui/material";
import { ACCOUNT_URL } from "../../../../utils/axiosInstance";

type PropType = {
  open: boolean;
  handleClose: () => void;
};
function SubRedirectionModal({ open, handleClose }: PropType) {
  const handleSubscrition = () => {
    window.open(`${ACCOUNT_URL}/subscription`, "_blank");
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Paper sx={{ padding: 2, borderRadius: 2, maxWidth: 400 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Unlock Premium Features
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Upgrade your experience and access exclusive premium features!
          Subscribe now to enjoy enhanced functionality, priority support, and
          more. Choose the plan that fits your needs and elevate your journey
          today..
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: "5px",
          }}
        >
          <Button onClick={handleClose} variant="qbookStyledBtn" color="error">
            Close
          </Button>
          <Button
            onClick={handleSubscrition}
            variant="qbookStyledBtn"
            color="success"
          >
            View subscriptions
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}

export default SubRedirectionModal;
