import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AccountCircle,
  AdminPanelSettingsOutlined,
  ArrowForwardIos,
  Contrast,
  DarkMode,
  Home,
  LightMode,
  Logout,
  Person,
} from "@mui/icons-material";
import {
  ACCOUNT_URL,
  DOMAIN_CONFIG_OBJ,
} from "../../../../../utils/axiosInstance";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../reduxStore/hooks";
import { toggleTheme } from "../../../../../reduxStore/features/themeSlice";
import { signOutUser } from "../../../../../reduxStore/middleware/authMiddleware";
import Swal from "sweetalert2";

const AccountMenuDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorTh, setAnchorTh] = useState<HTMLElement | null>(null);
  const {
    userData,
    email,
    isLoading: userLoading,
  } = useAppSelector((state) => state.auth);
  const { isDark } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((prev) => (prev === null ? event.currentTarget : null));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleThemeMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorTh(event.currentTarget);
  };

  const handleThemeMenuClose = () => {
    setAnchorTh(null);
  };

  const handleThemeChange = () => {
    if (isDark) {
      localStorage.setItem("qbookTheme", "light");
    } else {
      localStorage.setItem("qbookTheme", "dark");
    }
    document?.querySelector("html")?.toggleAttribute("data-dark-mode");
    dispatch(toggleTheme(!isDark));
  };

  const handleAdminClick = () => {
    if (window.location.pathname.includes("admin")) {
      navigate("/");
    } else {
      navigate("/admin");
    }
  };

  const viewAccount = () => {
    window.location.href = ACCOUNT_URL;
  };

  const viewProfile = () => {
    console.log(DOMAIN_CONFIG_OBJ.domainName);
    if (DOMAIN_CONFIG_OBJ.domain.includes("quera")) {
      navigate(`/profile/${email}`);
    } else {
      window.location.href = `${ACCOUNT_URL}/user-profile`;
    }
  };

  const signOutHandler = () => {
    // when user sign out revert back to light theme
    // isDark && handleThemeChange();
    dispatch(
      signOutUser({ avoidClearingCookies: false, logoutRedirect: true }),
    );
    Swal.fire({
      title: "Logged out!",
      text: "Your have been successfully logged out.",
      confirmButtonColor: "var(--accent-clr2)",
      inputAttributes: {
        id: "confirm-logout",
      },
    });
  };

  return (
    <Box
      aria-controls="menu-appbar"
      display="flex"
      alignItems="center"
      gap={0.5}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 100,
        p: 0.25,
      })}
    >
      <IconButton
        onClick={handleMenu}
        cy-data="menu-btn"
        sx={{ position: "relative" }}
      >
        <MenuIcon fontSize="small" cy-data="menu-wrapper" />
        {userLoading && (
          <CircularProgress
            sx={{
              position: "absolute",
              width: "35px !important",
              height: "35px !important",
              color: "lightgray",
            }}
          />
        )}
      </IconButton>

      <Badge
        overlap="circular"
        badgeContent="2"
        color="secondary"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        variant="dot"
        onClick={handleMenu}
        sx={{ cursor: "pointer" }}
      >
        <Avatar
          cy-data="menu-user-avatar"
          alt="user avatar"
          sx={{ width: 32, height: 32, fontSize: 16 }}
          src={userData?.personalInformation?.profilePhoto}
        >
          {userData?.firstName[0] || userData?.userName?.split(" ")[0][0]}
        </Avatar>
      </Badge>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ marginTop: 2 }}
        disableScrollLock
      >
        <Paper elevation={0} sx={{ width: 200 }}>
          <MenuItem className="email-menu_item">
            <Typography
              color="text.secondary"
              fontSize={14}
              cy-data="menu-user-email"
            >
              {email}
            </Typography>
          </MenuItem>
          <Divider className="email-menu_item" />
          <MenuItem onClick={handleAdminClick} cy-data="menu-item-admin">
            <ListItemIcon>
              {window.location.pathname.includes("admin") ? (
                <Home fontSize="small" />
              ) : (
                <AdminPanelSettingsOutlined fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {window.location.pathname.includes("admin") ? "Home" : "Admin"}
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={viewProfile} cy-data="menu-item-profile">
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Profile
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={viewAccount} cy-data="menu-item-account">
            <ListItemIcon>
              <AccountCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Account
            </ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={handleThemeMenuOpen}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
            value="theme"
            cy-data="menu-item-theme"
          >
            <ListItemIcon>
              <Contrast fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Theme
            </ListItemText>
            <ArrowForwardIos fontSize="small" />
          </MenuItem>
          <Menu
            anchorEl={anchorTh}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
            open={Boolean(anchorTh)}
            onClose={handleThemeMenuClose}
            onClick={handleThemeMenuClose}
          >
            <MenuItem onClick={handleThemeChange}>
              <ListItemIcon>
                <LightMode fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                Light theme
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleThemeChange}>
              <ListItemIcon>
                <DarkMode fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                Dark theme
              </ListItemText>
            </MenuItem>
          </Menu>
          <MenuItem
            onClick={signOutHandler}
            id="logoutTarget"
            cy-data="menu-item-logout"
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Logout
            </ListItemText>
          </MenuItem>
        </Paper>
      </Menu>
    </Box>
  );
};

export default AccountMenuDropdown;
