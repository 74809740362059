/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ModalTypes } from "../..";
import logo from "../../../../../assets/img/queraLogo.svg";
import OTPInput from "react-otp-input";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "../../../../../reduxStore/hooks";
import { UserSubmitNewPasswordsAction } from "../../../../../reduxStore/middleware/authMiddleware";
import { DOMAIN_CONFIG_OBJ } from "../../../../../utils/axiosInstance";
import { QBOOK_DOMAIN_VALUES } from "../../../../../utils/constants/domainNameValues";

interface ISubmitPasswordProps {
  open: boolean;
  toggleAuthFlowPage: (modalToBeOpened: ModalTypes) => void;
  email: string;
}

const INITIAL_VALUES = { password: "", confirmPassword: "" };
const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Must be 8 characters or more")
    .required("Password is required")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .min(8, "Must be 8 characters or more")
    .required("Password is required")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number"),
});

const SubmitPassword = ({
  open,
  toggleAuthFlowPage,
  email,
}: ISubmitPasswordProps) => {
  const [otp, setOtp] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [_openSnack, setOpenSnack] = useState<boolean>(false);
  const [_snackMessage, setSnackMessage] = useState<string>("");
  const [_snackColor, setSnackColor] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { password } = values;
      const res = await dispatch(
        UserSubmitNewPasswordsAction({
          email: email,
          newPassword: password,
          code: otp,
        }),
      );
      console.log(res);
      if (res.payload === "Success") {
        setSnackColor(false);
        setOpenSnack(true);
        setSnackMessage("Password Successfully Updated");
      } else {
        setSnackColor(true);
        setOpenSnack(true);
        setSnackMessage(res.meta.requestStatus);
      }
      toggleAuthFlowPage("login");
    },
  });

  const showPasswordHandler = (): void => setShowPassword((prev) => !prev);
  const showConfirmPasswordHandler = (): void => {
    setShowConfirmPassword((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      hideBackdrop
      PaperProps={{
        sx: {
          backgroundColor: "var(--auth-form-bg)",
          width: fullScreen ? "100%" : 480,
          p: fullScreen ? "1em" : "1em 3em",
          borderRadius: "8px",
          boxShadow: "0px 1px 15px rgba(199, 199, 199, 0.5)",
          color: theme.palette.common.white,
        },
      }}
      aria-label="submit password"
      cy-data="reset-password"
    >
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            className={`login-logo ${
              DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.qusteam
                ? "login-logo-qusteam"
                : DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.qbraid
                  ? "login-logo-qbraid"
                  : ""
            }`}
            src={DOMAIN_CONFIG_OBJ.darkThemeLogo}
            alt="qbook logo"
            cy-data="login-logo"
          />
        </Grid>
      </Grid>
      <DialogTitle textAlign="center" fontSize={28} pt={0} color="#f5f5f5">
        Reset Password
      </DialogTitle>
      <DialogContent>
        <Typography fontSize={14} pb={1}>
          Enter the security code from your email
        </Typography>
        <Stack flexDirection="row">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            placeholder="------"
            renderInput={(props) => <input {...props} />}
            containerStyle={{
              gap: "8px",
              flex: 1,
              justifyContent: "space-between",
            }}
            inputStyle={{
              flex: 1,
              height: "50px",
              color: "white",
              backgroundColor: "rgba(18, 18, 18, 0.58)",
              border: "1px solid transparent",
              borderRadius: "10px",
              fontFamily: "var(--global-font-family-primary), sans - serif",
              outline: "none",
            }}
            data-testid="reset-psd-otp-input"
          />
        </Stack>
        <form spellCheck="false" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} mt={3}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <Typography fontSize={13} color="var(--text-inverted-clr1)">
                  Enter a new password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your new password"
                  cy-data="password"
                  required
                  fullWidth
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <VisibilityOff
                            onClick={showPasswordHandler}
                            sx={{
                              color: "#2E3338",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <Visibility
                            onClick={showPasswordHandler}
                            sx={{
                              color: "#2E3338",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <Typography fontSize={13}>Retype the same password</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  cy-data="confirm-password"
                  required
                  fullWidth
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showConfirmPassword ? (
                          <VisibilityOff
                            onClick={showConfirmPasswordHandler}
                            sx={{
                              color: "#2E3338",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <Visibility
                            onClick={showConfirmPasswordHandler}
                            sx={{
                              color: "#2E3338",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="qbookAuth"
                size="large"
                color="authSubmit"
                type="submit"
                value="submit"
                cy-data="submit"
                fullWidth
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                cy-data="cancel-psd-change"
                variant="qbookAuth"
                size="large"
                color="authCancel"
                fullWidth
                onClick={() => toggleAuthFlowPage("login")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SubmitPassword;
