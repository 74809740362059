import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import {
  authForgetPasswordAsync,
  authSignInAsync,
  authSignOutAsync,
  authSignUpAsync,
  authSubmitPasswordAsync,
  checkForExistingUserAsync,
  createUserAttributes,
  federatedSignInAsync,
  fetchUserAsync,
  getUserBilling,
  getUserFromCookies,
  getUserIdentity,
  getUserInfoAsync,
  pollAuthUserAsync,
} from "../services/authService";
import { generateAuthCookies } from "../features/authSlice";
import {
  clearCookies,
  clearLocalStorage,
  getEmailRefreshCookies,
} from "../../utils/cookieHelper";
import {
  AuthSignUpTypes,
  PollUserMiddlewarePayloadType,
} from "../types/AuthTypes";
import { handleSnackbarOpen } from "../features/snackbarSlice";
import { clearUserBookmarkedBlogs } from "../features/blogSlice";
import { DOMAIN_CONFIG_OBJ } from "../../utils/axiosInstance";

export const checkStorageForSessions = () => {
  const { email, refreshToken } = getEmailRefreshCookies();
  if (email && refreshToken) {
    return true;
  }
  return false;
};

export const fetchUser = createAsyncThunk(
  "auth/fetchUser",
  async (refresh: boolean, thunkAPI) => {
    try {
      const res = await fetchUserAsync(refresh);
      if (res?.attributes === undefined) {
        const updatedResponse = await createUserAttributes(res);
        thunkAPI.dispatch(generateAuthCookies(updatedResponse));
        return thunkAPI.fulfillWithValue(updatedResponse);
      }
      if (res?.username) {
        thunkAPI.dispatch(generateAuthCookies(res));
        return thunkAPI.fulfillWithValue(res);
      }
    } catch (err) {
      try {
        let { email, refreshToken } = getEmailRefreshCookies();
        if (email && refreshToken) {
          console.log("Email and token found attempting api login");
          const response = await getUserFromCookies(email, refreshToken);
          if (response) {
            const { data } = response;
            let storageNamespacePrefix =
              "CognitoIdentityServiceProvider." + data?.clientId + ".";
            try {
              let storage = window.localStorage;
              ["idToken", "accessToken", "refreshToken"].forEach((token) =>
                storage.setItem(
                  storageNamespacePrefix + data.cognitoUser + "." + token,
                  data[token],
                ),
              );
              storage.setItem(
                storageNamespacePrefix + "LastAuthUser",
                data.cognitoUser,
              );
              thunkAPI.dispatch(fetchUser(refresh));
            } catch (e) {
              thunkAPI.dispatch(
                signOutUser({
                  avoidClearingCookies: false,
                  logoutRedirect: false,
                }),
              );
              return thunkAPI.rejectWithValue({
                message: "Refresh failed api error",
              });
            } // user may have disabled local storage, and thus auto-login
          } else {
            console.log("Email+token api -ve, performing session logout");
            thunkAPI.dispatch(
              signOutUser({
                avoidClearingCookies: false,
                logoutRedirect: false,
              }),
            );
            return thunkAPI.rejectWithValue({
              message: "Refresh failed api error",
            });
          }
        } else {
          console.log("Email, token not found, performing session log out");
          thunkAPI.dispatch(
            signOutUser({
              avoidClearingCookies: false,
              logoutRedirect: false,
            }),
          );
          return thunkAPI.rejectWithValue({
            message: "email and refresh token not found",
          });
        }
      } catch (error: any) {
        console.log(
          "Attempt to email+token login failed, signing out user session",
        );
        thunkAPI.dispatch(
          signOutUser({
            avoidClearingCookies: false,
            logoutRedirect: false,
          }),
        );
        return thunkAPI.rejectWithValue({ message: "user not found" });
      }
    }
  },
);

export const federatedSignIn = createAsyncThunk(
  "auth/federatedSignIn",
  async (provider: any, thunkAPI) => {
    try {
      const res = await federatedSignInAsync(provider);
      return thunkAPI.fulfillWithValue(res);
    } catch (error: any) {
      thunkAPI.rejectWithValue({ message: "Federated signin failed" });
    }
  },
);

export const authSignIn = createAsyncThunk(
  "auth/signIn",
  async (
    { email, password }: { email: string; password: string },
    thunkAPI,
  ) => {
    try {
      const res = await authSignInAsync(email, password);
      console.log("response from auth sign in ", res);
      if (res?.username) {
        thunkAPI.dispatch(generateAuthCookies(res));
        thunkAPI.dispatch(
          handleSnackbarOpen({
            open: true,
            message: "Login successful",
            severity: "success",
          }),
        );
        return thunkAPI.fulfillWithValue(res);
      } else {
        throw new Error("Login failed");
      }
    } catch (error: any) {
      console.log(error);
      thunkAPI.rejectWithValue({ message: error.message });
    }
  },
);

export const authSignUp = createAsyncThunk(
  "auth/signup",
  async ({ values, attributes }: AuthSignUpTypes, thunkAPI) => {
    try {
      const user = await checkForExistingUserAsync(values?.email?.toString());
      if (!user.data.exists) {
        const response = await authSignUpAsync({ values, attributes });
        if (response) {
          if (response.user) {
            thunkAPI.dispatch(
              handleSnackbarOpen({
                open: true,
                severity: "success",
                message:
                  "Successfully signed up! Please check your email inbox for a verification link.",
              }),
            );
            return thunkAPI.fulfillWithValue(response);
          }
        } else {
          throw new Error("Sign up unsuccessful!");
        }
      } else {
        thunkAPI.dispatch(
          handleSnackbarOpen({
            open: true,
            severity: "error",
            message: user.data.message,
          }),
        );
        return thunkAPI.rejectWithValue({ message: user.data.message });
      }
      return thunkAPI.rejectWithValue({ message: "User already exists" });
    } catch (err: any) {
      thunkAPI.dispatch(
        handleSnackbarOpen({
          open: true,
          severity: "error",
          message: "Sign up unsuccessful!",
        }),
      );
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const signOutUser = createAsyncThunk(
  "auth/signOut",
  async (
    {
      avoidClearingCookies,
      logoutRedirect,
    }: { avoidClearingCookies: boolean; logoutRedirect: boolean },
    thunkAPI,
  ) => {
    try {
      window.localStorage.setItem("loggingOut", "true");
      localStorage.clear();
      await authSignOutAsync();
      !avoidClearingCookies && clearCookies();
      clearLocalStorage();
      thunkAPI.dispatch(clearUserBookmarkedBlogs());
      thunkAPI.fulfillWithValue({ message: "user signed out" });
      if (logoutRedirect) {
        window.location.href = DOMAIN_CONFIG_OBJ.awsCognito.cognitoLogoutUrl;
      }
    } catch (error) {
      thunkAPI.rejectWithValue({ message: "cant sign out" });
    }
  },
);

export const UserForgotPasswordAction = createAsyncThunk(
  "auth/forgotPassword",
  async (email: string, thunkAPI) => {
    try {
      const response = await authForgetPasswordAsync(email);
      if (response) {
        thunkAPI.dispatch(
          handleSnackbarOpen({
            open: true,
            severity: "info",
            message: "Check your email for OTP!",
          }),
        );
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  },
);

export const UserSubmitNewPasswordsAction = createAsyncThunk(
  "auth/submitNewPassword",
  async (
    {
      email,
      newPassword,
      code,
    }: { email: string; code: string; newPassword: string },
    thunkAPI,
  ) => {
    try {
      await authSubmitPasswordAsync({
        email,
        password: newPassword,
        code,
      });
      thunkAPI.dispatch(
        handleSnackbarOpen({
          open: true,
          severity: "success",
          message: "Password changed!",
        }),
      );
      return "success";
    } catch (err) {
      console.log(err);
    }
  },
);

export const pollUserAction = createAsyncThunk(
  "auth/pollUser",
  async ({ count }: PollUserMiddlewarePayloadType, thunkAPI) => {
    let LIMIT = 15; // maximum number of polls allowed
    let DELAY = 200; // millisecond delay between polls
    try {
      const response = await pollAuthUserAsync();
      let seconds = ((count + 1) * DELAY) / 1000;
      console.log(`poll ${count + 1} @ ${seconds}s`);
      if (response !== undefined) {
        if (response?.attributes === undefined) {
          const updatedResponse = await createUserAttributes(response);
          thunkAPI.dispatch(generateAuthCookies(updatedResponse));
          return thunkAPI.fulfillWithValue(updatedResponse);
        }
        thunkAPI.dispatch(generateAuthCookies(response));
        return thunkAPI.fulfillWithValue(response);
      } else if (++count > LIMIT - 1) {
        return thunkAPI.rejectWithValue({
          success: false,
          message: "Polling failed",
        });
      } else {
        console.log("No response re-polling");
        setTimeout(() => {
          return thunkAPI.dispatch(pollUserAction({ count: count++ }));
        }, DELAY);
      }
    } catch (error) {
      let seconds = ((count + 1) * DELAY) / 1000;
      console.log(`Error : poll ${count + 1} @ ${seconds}s`);
      if (++count > LIMIT - 1) {
        return thunkAPI.rejectWithValue({
          success: false,
          message: "Polling failed",
        });
      } else {
        setTimeout(() => {
          return thunkAPI.dispatch(pollUserAction({ count: count++ }));
        }, DELAY);
      }
    }
  },
);

export const getUserInfoAction = createAsyncThunk(
  "auth/getUserData",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse<any, any> = await getUserInfoAsync();
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      }
    } catch (error: any) {
      thunkAPI.rejectWithValue({
        success: false,
        message: error.message,
      });
    }
  },
);

export const getUserIdentityAction = createAsyncThunk(
  "auth/userIdentity",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse<any, any> = await getUserIdentity();
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "identity check failed",
      });
    }
  },
);

export const getUserBillingAction = createAsyncThunk(
  "auth/userBilling",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse<any, any> = await getUserBilling();
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "billing retrieval failed",
      });
    }
  },
);

// Will come in handy when upgrading aws v4 to v6
// export const userUpdatePasswordAction = createAsyncThunk(
//     "auth/updatePassword",
//     async (
//         {
//             cognitoUser,
//             currentPassword,
//             oldPassword,
//         }: { cognitoUser: any; currentPassword: string; oldPassword: string },
//         thunkAPI
//     ) => {
//         try {
//             updatePasswordAsync(cognitoUser, currentPassword, oldPassword)
//                 .then(() => {
//                     return thunkAPI.fulfillWithValue("success");
//                 })
//                 .catch(() => {
//                     return thunkAPI.rejectWithValue("failure");
//                 });
//         } catch (err) {
//             return thunkAPI.rejectWithValue({
//                 message: "password update failed",
//             });
//         }
//     }
// );
