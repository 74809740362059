import axiosClient from "../../utils/axiosInstance";

export const retrieveAdminCourses = () => {
  const response = axiosClient().get("/learn/courses");
  return response;
};

export const retrieveStudentsSolutionInCourseForFreeResponse = (
  userId: string,
  courseId: string,
  pageNumber: number,
) => {
  const response = axiosClient().get(
    `/reviewer/retrieve/free-response/user/submitted/solutions/${userId}/${courseId}/${pageNumber}`,
  );
  return response;
};

export const retrieveStudentsSolutionInCourseForMcq = (
  userId: string,
  courseId: string,
  pageNumber: number,
) => {
  const response = axiosClient().get(
    `/reviewer/retrieve/mcq/user/submitted/solutions/${userId}/${courseId}/${pageNumber}`,
  );
  return response;
};

export const updateAdminReviewForUsersFrqSolution = (payload: any) => {
  const response = axiosClient().post("/reviewer/submit/free-response/review", {
    ...payload,
  });
  return response;
};

export const loadSingleCourseData = (courseId: string) => {
  const response = axiosClient().get(`/learn/course-by-id/${courseId}`);
  return response;
};

export const sendAttachedEmail = (
  to: string,
  message: string,
  subject: string,
  base64Data: string,
) => {
  const response = axiosClient().post("/user/send-attachment-email", {
    to,
    message,
    subject,
    base64Data,
  });
  return response;
};

export const getBase64ImageFromURL = async (url: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    // Get base64 data
    const data = await new Promise((resolve) => {
      reader.onloadend = () => resolve(reader.result);
    });
    return data;
  } catch (error) {
    console.error("Error fetching and converting image:", error);
  }
};

export const updateUserCertificateStatus = (
  userId: string,
  courseId: string,
  status: boolean,
) => {
  const response = axiosClient().put(
    `/learn/user-certificate-status/${userId}/${courseId}`,
    { certificateStatus: status },
  );
  return response;
};
