import Auth from "@aws-amplify/auth";
import axiosClient, { LAB_URL } from "../../utils/axiosInstance";
import { AuthSignUpTypes, ISignUpResult } from "../types/AuthTypes";
import { AxiosError, AxiosResponse } from "axios";

export const createUserAttributes = async (user: any) => {
  // The OAuth login process differs slightly,
  // so the attributes must be attached in the same was as regular login.
  let params = user.signInUserSession.idToken.payload;
  await Object.assign(user, {
    attributes: params,
  });
  return user;
};

export const fetchUserAsync = (refresh: boolean): any => {
  return Auth.currentAuthenticatedUser({ bypassCache: refresh });
};
export const federatedSignInAsync = (provider: any, customState?: any): any => {
  // For all social logins, we provide the user with a link to the OAuth provider.
  return new Promise((resolve, reject) => {
    Auth.federatedSignIn({
      provider: provider,
      customState: customState,
    }).catch((err) => {
      return reject(err);
    });
  });
};

export const authSignInAsync = (
  email: string,
  password: string,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    Auth.signIn(email, password)
      .then(async (user) => {
        return resolve(user);
      })
      .catch(reject);
  });
};

export const authSignUpAsync = ({
  values,
  attributes,
}: AuthSignUpTypes): Promise<ISignUpResult> => {
  return new Promise((resolve, reject) => {
    Auth.signUp({
      username: values.email,
      password: values.password,
      attributes: attributes,
    })
      .then(async (user) => {
        return resolve(user);
      })
      .catch(reject);
  });
};

export const authForgetPasswordAsync = (email: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(email)
      .then(async (user) => {
        return resolve(user);
      })
      .catch(reject);
  });
};

export const authSubmitPasswordAsync = ({
  email,
  password,
  code,
}: {
  email: string;
  password: string;
  code: string;
}): Promise<any> => {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(email, code, password)
      .then(async (user) => {
        return resolve(user);
      })
      .catch(reject);
  });
};

export const authSignOutAsync = (): any => {
  const response = Auth.signOut({ global: true });
  return response;
};

export const getUserFromCookies = (
  email: string,
  refreshToken: string,
): any => {
  const response = axiosClient().post("/auth/login", {
    email: email,
    password: refreshToken,
    mode: "refresh",
  });
  return response;
};

export const signUpAsync = (
  email: string,
  password: string,
  attributes: any,
  duplicate_users: any,
) => {
  return new Promise((resolve, reject) => {
    let credentials = {
      username: email,
      password: password,
    };
    // If it's underfined then there are no duplicate users
    if (duplicate_users.cognitoId === undefined) {
      Auth.signUp(
        attributes
          ? Object.assign(credentials, { attributes: attributes })
          : credentials,
      )
        .then(async (newUser) => {
          return resolve(newUser);
        })
        .catch(reject);
    } else {
      return reject({
        message:
          "An account with the given email already exists. Please log in.",
      });
    }
  });
};

export const checkForUserAsync = (email: string) => {
  const response = axiosClient().get("/search-user-cognitoId/" + email);
  return response;
};

export const checkForExistingUserAsync = (email: string) => {
  // Check if the user already exists in the database
  // returns user object if it exists
  const response = axiosClient().get(`/auth/check-for-user/${email}`);
  return response;
};

export const addUserToMongo = (param: any, clientId?: any) => {
  let userData = {
    cognitoId: param.sub,
    jupyterUsername: param.sub,
    email: param.email,
    role: "guest",
  };
  const response = axiosClient().post("/user/handle-post-confirmation", {
    userData,
  });
  return response;
};

export const getUserInfoAsync = () => {
  const response = axiosClient().get("/get-user-info");
  return response;
};

export const pollAuthUserAsync = () => {
  // When redirected to OAuth callback url,
  // we query the local storage to authenticate the user and create their cookies.
  return Auth.currentAuthenticatedUser();
};

export const forgotPasswordAsync = (username: string) => {
  // Send confirmation code to user's email
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch((err) => console.log(err));
  });
};

// Change password while logged out
// used in resetPassword modal which can be accessed from login modal
export const resetPasswordAsync = (
  username: string,
  code: any,
  new_password: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => console.log(err));
  });
};

// Change password while logged in with current user
// different from resetPassword
export const updatePasswordAsync = (
  cognitoUser: any,
  currentPassword: string,
  oldPassword: string,
): Promise<"SUCCESS"> => {
  return new Promise((resolve, reject) => {
    Auth.changePassword(cognitoUser, currentPassword, oldPassword)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

// makes OTP api call
export const verifyOTPAsync = async (
  otp: string,
): Promise<AxiosResponse<any, any>> => {
  return new Promise((resolve, reject) => {
    axiosClient()
      .post("/verify-delete-account-otp", {
        otp: otp,
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

// sends the otp on delete account
export const deleteAccountOTPAsync = async (): Promise<
  AxiosResponse<any, any> | AxiosError<any, any>
> => {
  return new Promise((resolve, reject) => {
    axiosClient()
      .post("/send-delete-account-otp")
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

// make an apis call for delete
export const deleteAccountAsync = async () => {
  try {
    const response = await axiosClient().put("/user/delete", {
      data: {
        labUrl: LAB_URL,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (err: any) {
    return err;
  }
};

// TODO: Currently not working, but is intended for
//  getting the token provided by lab.
export const getLabToken = async () => {
  return fetch(LAB_URL + "/hub/api/authorizations/token", {
    method: "POST",
  })
    .then(() => console.log("Grabbing new API key successful"))
    .catch((error) => {
      console.log("There was an error!", error);
    });
};

//  TODO: This request reaches out to jupyterhub to delete
//   the user's server. In doing so the jupyterhub cookies are
//   no longer refereshed.
export const deleteUserServerAsync = (email: string, refreshToken: string) => {
  const data = {
    email: email,
    password: refreshToken,
    labUrl: LAB_URL,
  };
  const response = axiosClient().delete("/auth/lab-server", { data: data });
  return response;
};

//TODO: Jupyterhub has a qBraid server extension
// which clears the users lab cookies
export const getQbraidServerExtensionAsync = (email: string) => {
  const response = axiosClient().delete("/auth/qbraid-server-extension", {
    data: {
      email: email,
      labUrl: LAB_URL,
    },
  });
  return response;
};

export const renderLabLogout = (email: string, refreshToken: string) => {
  //Calls qBraid LogoutHandler to render custom logout.
  const response = axiosClient().delete("/auth/logout", {
    data: {
      email: email,
      password: refreshToken,
      labUrl: LAB_URL,
    },
  });
  return response;
};

export const getUserIdentity = () => {
  const response = axiosClient().get("/identity");
  return response;
};

export const searchUserQbookQuery = (query: string) => {
  const response = axiosClient().get(`/public/search-qbook/${query}`);
  return response;
};
export const getUserBilling = () => {
  const response = axiosClient().get("/billing/get-user-billing");
  return response;
};
