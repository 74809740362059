/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Skeleton,
  Slide,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import GroupIcon from "@mui/icons-material/Group";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CachedIcon from "@mui/icons-material/Cached";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../reduxStore/hooks";
import UserTableList from "./courseTable";
import {
  addCourseIdInToRefreshingCourses,
  removeCourseIdFromRefreshingCourses,
  setSelectedUser,
  setShowGrader,
} from "../../../../../../reduxStore/features/adminCourseSlice";
import { QuestionTypes } from "./AnswerAccordion";
import {
  loadSingleCourseDataAction,
  retrieveStudentsSolutionInCourseForFreeResponseAction,
  retrieveStudentsSolutionInCourseForMcqAction,
} from "../../../../../../reduxStore/middleware/adminCourseMiddleware";
import ImageIcon from "@mui/icons-material/Image";
import CertificateGenerator from "./CertificateGenerator";
import noPreviewAvailable from "../../../../../../assets/img/NoPreviewAvailable.jpg";

const AnswerAccordion = React.lazy(() => import("./AnswerAccordion"));
const imageFormats = ["png", "jpeg", "jpg", "svg"];
const styles = {
  container: {
    padding: { lg: "1em", md: ".5em", sm: "1em .5em", xs: "1em .5em" },
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    boxShadow: "0px 6px 16px 0px #0000001F",
    borderRadius: "10px",
    height: "fit-content",
  },
  tabContainer: {
    width: "inherit",
  },
  tabs: {
    "& .MuiTab-root": {
      minWidth: 0,
    },
    marginBottom: "1em",
  },
  icon: {
    fontSize: "2rem",
    cursor: "grab",
  },
  closeButton: {
    width: "25px",
    height: "25px",
  },
  templateBox: {
    width: "inherit",
    boxShadow: 1,
    borderRadius: "5px",
    padding: "10px",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexGap: {
    display: "flex",
    gap: "1em",
  },
};
type PaginationType = {
  total: number;
  page: number;
  resultsPerPage: number;
  numPages: number;
};

const initialPagination: PaginationType = {
  total: 0,
  page: 1,
  resultsPerPage: 5,
  numPages: 0,
};
type Props = {};
const GraderUi = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    selectedCourse,
    refreshingCourses,
    selectedUser,
    selectedCourseUserList: userList,
  } = useAppSelector((state) => state.adminCourse);

  const [userProgressValue, setUserProgressValue] = useState<number>(0);
  const [loadingUserFrqAnswers, setLoadingUserFrqAnswers] =
    useState<boolean>(false);
  const [loadingUserMcqAnswers, setLoadingUserMcqAnswers] =
    useState<boolean>(false);
  // const [loadingUserCqAnswers, setLoadingUserCqAnswers] = useState(false);

  const [slideIn, setSlideIn] = useState<boolean>(true);
  const [selectedUserFrqResponse, setSelectedUserFrqResponse] = useState<
    Array<any>
  >([]);
  const [selectedUserMcqResponse, setSelectedUserMcqResponse] = useState<
    Array<any>
  >([]);
  // const [selectedUserCqResponse, setSelectedUserCqResponse] = useState([]);
  const [slideDirection, setSlideDirection] = useState<boolean>(true);

  const [certificateLoading, setCertificateLoading] = useState<boolean>(false);
  const [showTemplate, setShowTemplate] = useState<boolean>(false);
  const [frqTemplate, setFrqTemplate] = useState<String>("");
  const [frqTemplateQuestion, setFrqTemplateQuestion] = useState<String>("");
  const [frqTemplateImgs, setFrqTemplateImgs] = useState<Array<any>>([]);
  const [graderTabValue, setGraderTabValue] = useState("template");
  const frqPagination = useRef<PaginationType>(initialPagination);
  const mcqPagination = useRef<PaginationType>(initialPagination);

  const handleBackToCourses = useCallback(() => {
    dispatch(setShowGrader(false));
  }, [dispatch]);

  const handleTabChange = useCallback(
    (newValue) => {
      setGraderTabValue(newValue);
    },
    [setGraderTabValue],
  );

  const updateUserProgressValue = useCallback(
    (value: number) => {
      setUserProgressValue(value);
    },
    [setUserProgressValue],
  );

  const loadUsersFrqAnswerSubmitted = useCallback(async () => {
    const courseId = selectedCourse?._id;
    const userId = selectedUser?.userId;
    if (userId && courseId) {
      console.log("[!] ", frqPagination.current.page - 1);
      setLoadingUserFrqAnswers(true);
      const frqResponses = await dispatch(
        retrieveStudentsSolutionInCourseForFreeResponseAction({
          userId: userId,
          courseId: courseId,
          pageNumber: frqPagination.current.page - 1,
        }),
      );
      if (frqResponses?.payload?.usersFrqSolutions) {
        setSelectedUserFrqResponse(frqResponses?.payload?.usersFrqSolutions);
        frqPagination.current = {
          total: Number(frqResponses?.payload?.pagination?.total) || 0,
          page: Number(frqResponses?.payload?.pagination?.page) + 1 || 1,
          resultsPerPage:
            Number(frqResponses?.payload?.pagination?.resultsPerPage) || 5,
          numPages: Number(frqResponses?.payload?.pagination?.numPages) || 0,
        } as PaginationType;
      } else {
        setSelectedUserFrqResponse([]);
        frqPagination.current = initialPagination;
      }
      setLoadingUserFrqAnswers(false);
    }
  }, [
    selectedCourse,
    selectedUser,
    dispatch,
    setLoadingUserFrqAnswers,
    setSelectedUserFrqResponse,
  ]);

  const loadUsersMcqAnswerSubmitted = useCallback(async () => {
    const courseId: string = selectedCourse?._id;
    const userId = selectedUser?.userId;
    if (userId && courseId) {
      setLoadingUserMcqAnswers(true);
      const mcqResponse = await dispatch(
        retrieveStudentsSolutionInCourseForMcqAction({
          userId: userId,
          courseId: courseId,
          pageNumber: mcqPagination.current.page - 1,
        }),
      );
      if (mcqResponse?.payload?.usersMcqSolutions) {
        setSelectedUserMcqResponse(mcqResponse?.payload?.usersMcqSolutions);
        mcqPagination.current = {
          total: Number(mcqResponse?.payload?.pagination?.total) || 0,
          page: Number(mcqResponse?.payload?.pagination?.page) + 1 || 1,
          resultsPerPage:
            Number(mcqResponse?.payload?.pagination?.resultsPerPage) || 5,
          numPages: Number(mcqResponse?.payload?.pagination?.numPages) || 0,
        } as PaginationType;
      } else {
        setSelectedUserMcqResponse([]);
        mcqPagination.current = initialPagination;
      }
      setLoadingUserMcqAnswers(false);
    }
  }, [
    selectedCourse,
    selectedUser,
    dispatch,
    setLoadingUserMcqAnswers,
    setSelectedUserMcqResponse,
  ]);

  const initialLoader = useCallback(async () => {
    if (selectedUser?.userId) {
      // dispatch(addCourseIdInToRefreshingCourses(selectedCourse._id))
      updateUserProgressValue(parseFloat(selectedUser?.progress));
      await loadUsersMcqAnswerSubmitted();
      await loadUsersFrqAnswerSubmitted();
      dispatch(
        removeCourseIdFromRefreshingCourses({ courseId: selectedCourse._id }),
      );
    }
  }, [
    selectedUser,
    selectedCourse,
    loadUsersMcqAnswerSubmitted,
    loadUsersFrqAnswerSubmitted,
  ]);

  useEffect(() => {
    initialLoader();
  }, [initialLoader]);

  useEffect(() => {
    frqPagination.current = initialPagination;
    mcqPagination.current = initialPagination;
  }, [selectedUser]);

  const handleCourseRefresh = useCallback(
    async (loadResponseOnly?: boolean) => {
      if (loadResponseOnly) {
        setLoadingUserMcqAnswers(true);
        setLoadingUserFrqAnswers(true);
        setSelectedUserMcqResponse([]);
        setSelectedUserMcqResponse([]);
      } else {
        dispatch(
          addCourseIdInToRefreshingCourses({ courseId: selectedCourse._id }),
        );
      }
      await dispatch(
        loadSingleCourseDataAction({ courseId: selectedCourse._id }),
      );
    },
    [dispatch, selectedCourse._id],
  );

  const resetUserData = async () => {
    setUserProgressValue(0);
    await handleCourseRefresh(true);
  };

  const handleNextUserClick = useCallback(() => {
    resetUserData();
    const currentIndex = userList.findIndex(
      (user: any) => user.frontEndId === selectedUser.frontEndId,
    );
    if (currentIndex >= userList.length - 1) {
      return;
    }
    setSlideDirection(true);
    setSlideIn(false);
    dispatch(setSelectedUser(userList[currentIndex + 1]));
    setTimeout(() => {
      setSlideDirection(false);
      setSlideIn(true);
    }, 300);
  }, [userList, selectedUser, dispatch, resetUserData]);

  const handlePreviousUserClick = useCallback(() => {
    resetUserData();
    const currentIdex = userList.findIndex(
      (user: any) => user.frontEndId === selectedUser.frontEndId,
    );
    if (currentIdex <= 0) {
      return;
    }

    setSlideDirection(false);
    setSlideIn(false);
    dispatch(setSelectedUser(userList[currentIdex - 1]));
    setTimeout(() => {
      setSlideDirection(true);
      setSlideIn(true);
    }, 300);
  }, [userList, selectedUser, dispatch, resetUserData]);

  const getImage = useCallback((url: any) => {
    const imgExt = url.split(".").pop();
    if (imageFormats.includes(imgExt)) {
      return url;
    } else if (url.startsWith("data:image")) {
      return url;
    } else {
      return noPreviewAvailable;
    }
  }, []);

  const handleViewTemplate = (
    templateQuiz: string,
    tempateText?: string,
    templateImages?: any,
  ) => {
    setFrqTemplateQuestion(templateQuiz);
    if (tempateText) {
      setFrqTemplate(tempateText);
    } else {
      setFrqTemplate("");
    }
    if (templateImages?.length) {
      setFrqTemplateImgs(templateImages);
    } else {
      setFrqTemplateImgs([]);
    }
    setGraderTabValue("template");
    setShowTemplate(true);
  };

  const handleCloseTemplateView = () => {
    setFrqTemplate("");
    setFrqTemplateQuestion("");
    setFrqTemplateImgs([]);
    setShowTemplate(false);
  };

  const isFirstUser = useMemo(
    () =>
      userList.findIndex(
        (user) => user.frontEndId === selectedUser.frontEndId,
      ) <= 0,
    [userList, selectedUser],
  );

  const isLastUser = useMemo(
    () =>
      userList.findIndex(
        (user) => user.frontEndId === selectedUser.frontEndId,
      ) >=
      userList.length - 1,
    [userList, selectedUser],
  );
  const userFullName = useMemo(() => {
    if (!selectedUser?.firstName || !selectedUser?.lastName) {
      if (selectedUser?.userName) {
        return selectedUser.userName;
      }
      return "Not Available";
    }
    return `${selectedUser?.firstName} ${selectedUser?.lastName}`;
  }, [selectedUser]);

  const tabContent = useMemo(() => {
    switch (graderTabValue) {
      case "template":
        return (
          <Box
            sx={styles.templateBox}
            cy-data="admin-course-grader-frq-template-container"
          >
            <Box sx={styles.flexBetween}>
              <Box sx={styles.flexGap}>
                <Typography variant="h6">Template Response</Typography>
              </Box>
            </Box>
            <Box sx={styles.flexGap}>
              <p>
                <b>Question : </b>
                {frqTemplateQuestion || "Not Selected"}
              </p>
            </Box>
            <br />
            <p>
              <b>Solution : </b>
              {frqTemplate || "Template not available"}
            </p>
          </Box>
        );
      case "templateImage":
        return (
          <Box
            sx={{
              maxHeight: "500px",
              overflowX: "hidden",
              overflowY: "auto",
              width: "inherit",
              boxShadow: 1,
              borderRadius: "5px",
              padding: "10px",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
            }}
            cy-data="admin-course-grader-frq-template-image-container"
          >
            {frqTemplateImgs?.length === 0 ? (
              <Typography variant="body2">No documents available</Typography>
            ) : (
              frqTemplateImgs?.map((docItem: any, index: any) => {
                return (
                  <Box
                    sx={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "5px",
                      position: "relative",
                      overflow: "hidden",
                      cursor: "pointer",
                      ":hover": {
                        border: "1px solid var(--accent-clr2)",
                      },
                    }}
                    key={`frq_template_${index}`}
                  >
                    <img
                      src={getImage(docItem.questionTemplateFileUrl)}
                      alt="docsImg"
                      key={`docsImg${index}`}
                      width="100%"
                      height="100%"
                    />
                  </Box>
                );
              })
            )}
          </Box>
        );
      case "users":
        return (
          <Box
            sx={{
              maxHeight: "500px",
              overflowX: "hidden",
              overflowY: "auto",
              width: "inherit",
              boxShadow: 1,
              borderRadius: "5px",
              padding: "10px",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
            }}
            cy-data="admin-course-grader-frq-template-user-container"
          >
            <UserTableList graderTable={true} itemId={selectedCourse?._id} />
          </Box>
        );
      default:
        return null;
    }
  }, [graderTabValue, frqTemplateQuestion, frqTemplate]);

  const handleFrqPaginationChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      frqPagination.current = { ...frqPagination.current, page: value };
      loadUsersFrqAnswerSubmitted();
    },
    [loadUsersFrqAnswerSubmitted],
  );

  const handleMcqPaginationChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      console.log(
        "[!] value ",
        value,
        " pagination ",
        mcqPagination.current.page,
      );
      mcqPagination.current = { ...mcqPagination.current, page: value };
      console.log(
        "[!] value ",
        value,
        " pagination ",
        mcqPagination.current.page,
      );
      loadUsersMcqAnswerSubmitted();
    },
    [loadUsersMcqAnswerSubmitted],
  );

  const isGraderLoading = useMemo(
    () =>
      refreshingCourses.includes(selectedCourse?._id) ||
      loadingUserFrqAnswers ||
      loadingUserMcqAnswers,
    [
      refreshingCourses,
      selectedCourse,
      loadingUserMcqAnswers,
      loadingUserFrqAnswers,
    ],
  );
  return (
    <Grid
      container
      sx={{
        padding: { lg: "1em", md: ".5em", sm: "1em 5px", xs: "1em 5px" },
        gap: "1em",
      }}
      cy-data="admin-course-grader-container"
    >
      <Grid
        item
        container
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          gap: "1em",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            onClick={() => handleBackToCourses()}
            sx={{
              fontSize: "20px",
              fontWeight: "900",
              lineHeight: "27px",
              letterSpacing: "0em",
              textAlign: "left",
              cursor: "pointer",
              color: "var(--text-gray2)",
              ":hover": {
                color: "var(--text-primary-clr2)",
                scale: 1.2,
              },
            }}
          >{`My courses`}</Typography>
          <Box sx={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
            <Button
              variant="qbookStyledBtn"
              onClick={() => handleBackToCourses()}
              title="View Course List"
              sx={{ height: "26px", minWidth: "45px", padding: "5px" }}
              cy-data="admin-course-grader-go-back-btn"
            >
              <KeyboardBackspaceIcon />
            </Button>
            <Button
              variant="qbookStyledBtn"
              sx={{ height: "26px", minWidth: "45px", padding: "5px" }}
              onClick={() => handleCourseRefresh()}
              title="Refresh Course"
              cy-data="admin-course-grader-refresh-btn"
            >
              <CachedIcon />
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "25px", md: "35px", lg: "39px" },
              fontWeight: "900",
              lineHeight: "52px",
              letterSpacing: "0em",
              textAlign: "left",
              width: { xs: "300px", sm: "300px", md: "500px", lg: "100%" },
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: "var(--text-primary-clr1)",
            }}
            cy-data="admin-course-grader-course-name"
          >
            {selectedCourse?.courseName || "Selected Course Name"}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} gap={1} justifyContent="space-around">
        <Grid
          item
          container
          lg={6.8}
          md={6.8}
          sm={12}
          xs={12}
          sx={{
            boxShadow: "0px 6px 16px 0px #0000001F",
            borderRadius: "10px",
            minHeight: "500px",
            overflow: "auto",
            alignItems: "flex-start",
            alignContent: "flex-start",
            gap: "1em",
            padding: {
              lg: "1em 1em 0 1em",
              md: ".5em .5em 0 .5em",
              sm: "1em .5em 0 .5em",
              xs: "1em .5em 0 .5em",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: { lg: "1em", md: ".5em", sm: "0", xs: "0" },
              maxHeight: "80px",
            }}
          >
            {isFirstUser ? (
              <Box
                sx={{
                  minWidth: { xs: "6px", sm: "6px", md: "6px", lg: "64px" },
                }}
              />
            ) : (
              <Box>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "var(--text-primary-clr1)",
                    fontWeight: "600",
                    maxHeight: "30px",
                    minHeight: "30px",
                    padding: "0 5px",
                    display: { xs: "none", sm: "none", md: "none", lg: "flex" },
                  }}
                  onClick={() => handlePreviousUserClick()}
                  cy-data="admin-course-grader-previous-btn"
                  disabled={isGraderLoading}
                >
                  {"< Prior user"}
                </Button>
                <IconButton
                  sx={{
                    color: "var(--text-primary-clr1)",
                    display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
                  }}
                  onClick={() => handlePreviousUserClick()}
                  disabled={isGraderLoading}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Box>
            )}

            {isGraderLoading ? (
              <Skeleton width={150} variant="rounded" />
            ) : (
              <Typography
                sx={{
                  fontSize: { xs: "18px", sm: "20px", md: "24px", lg: "24px" },
                  fontWeight: 700,
                  lineHeight: "32px",
                  textAlign: "center",
                  width: { xs: "200px", sm: "200px", md: "500px" },
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "var(--text-primary-clr1)",
                }}
                cy-data="admin-course-grader-user-name"
              >
                {userFullName}
              </Typography>
            )}
            {isLastUser ? (
              <Box
                sx={{
                  minWidth: { xs: "6px", sm: "6px", md: "6px", lg: "64px" },
                }}
              />
            ) : (
              <Box>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "var(--text-primary-clr1)",
                    fontWeight: "600",
                    maxHeight: "30px",
                    minHeight: "30px",
                    padding: "0 5px",
                    display: { xs: "none", sm: "none", md: "none", lg: "flex" },
                  }}
                  onClick={() => handleNextUserClick()}
                  cy-data="admin-course-grader-next-btn"
                  disabled={isGraderLoading}
                >
                  {"Next user >"}
                </Button>
                <IconButton
                  sx={{
                    color: "var(--text-primary-clr1)",
                    display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
                  }}
                  onClick={() => handleNextUserClick()}
                  disabled={isGraderLoading}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              maxHeight: "66px",
            }}
          >
            <Typography
              sx={(theme) => ({
                minWidth: "90px",
                minHeight: "60px",
                padding: "5px",
                borderRadius: "12px",
                backgroundColor: "var(--accent-clr1)",
                color: "#ffffff",
                fontWeight: 700,
                fontSize: "17px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              })}
              cy-data="admin-course-grader-progress-btn"
            >
              {isGraderLoading ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : (
                `${parseFloat(userProgressValue.toString()).toFixed(2) || 0}/
              ${selectedCourse?.courseProgressPoints || 100}`
              )}
            </Typography>
          </Grid>
          <Slide direction={slideDirection ? "left" : "right"} in={slideIn}>
            <Grid
              item
              container
              xs={12}
              sx={{
                minHeight: "inherit",
                maxHeight: "20rem",
                overflowX: "hidden",
                overflowY: "auto",
                gap: "1em",
                position: "relative",
                padding: "8px 8px 0 8px",
              }}
            >
              <Grid
                item
                container
                xs={12}
                sx={{ minHeight: "30%", alignContent: "flex-start" }}
                cy-data="admin-course-grader-frq-container"
              >
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  minHeight={50}
                >
                  <Typography color="var(--text-primary-clr1)">
                    Free Response
                  </Typography>
                  <Button
                    variant="qbookStyledBtn"
                    sx={{ height: "26px", textTransform: "none" }}
                    // onClick={() => loadUsersFrqAnswerSubmitted()}
                    onClick={async () => {
                      handleCourseRefresh(true);
                    }}
                    cy-data="admin-grader-refresh-responses-btn"
                  >
                    Refresh User Responses
                  </Button>
                </Grid>
                {isGraderLoading ? (
                  Array(3)
                    .fill("")
                    .map((item: any, index: any) => (
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={50}
                        key={index}
                      />
                    ))
                ) : selectedUserFrqResponse.length === 0 ? (
                  <Typography variant="body1" color="text.primary">
                    No solutions submitted by user
                  </Typography>
                ) : (
                  <>
                    {selectedUserFrqResponse?.map(
                      (frqResponse: any, index: number) => (
                        <AnswerAccordion
                          key={`free-response-accordion-${index}`}
                          questionThemeColor="blue"
                          questionType={QuestionTypes.frq}
                          solution={frqResponse}
                          loadUsersAnswerSubmitted={loadUsersFrqAnswerSubmitted}
                          handleViewTemplate={handleViewTemplate}
                          setUserProgressValue={updateUserProgressValue}
                          userProgressValue={userProgressValue}
                          getImage={getImage}
                        />
                      ),
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        padding: "8px",
                        borderBottom: "1px solid lightgray",
                      }}
                    >
                      <Pagination
                        count={frqPagination.current.numPages}
                        page={frqPagination.current.page}
                        defaultPage={1}
                        siblingCount={0}
                        onChange={handleFrqPaginationChange}
                        // hidePrevButton
                        // hideNextButton
                      />
                    </Box>
                  </>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sx={{ minHeight: "30%" }}
                cy-data="admin-course-grader-mcq-container"
              >
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  minHeight={50}
                >
                  <Typography color="var(--text-primary-clr1)">
                    Multiple Choice Question
                  </Typography>
                </Grid>
                {isGraderLoading ? (
                  Array(3)
                    .fill("")
                    .map((item: any, index: any) => (
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={50}
                        key={index}
                      />
                    ))
                ) : selectedUserMcqResponse.length === 0 ? (
                  <Typography variant="body1" color="var(--text-primary-clr1)">
                    No solutions submitted by user
                  </Typography>
                ) : (
                  <>
                    {selectedUserMcqResponse.map(
                      (mcqResponse: any, index: any) => (
                        <AnswerAccordion
                          key={`mcq-response-accordion-${index}`}
                          questionThemeColor="violet"
                          questionType={QuestionTypes.mcq}
                          loadUsersAnswerSubmitted={loadUsersMcqAnswerSubmitted}
                          solution={mcqResponse}
                          setUserProgressValue={updateUserProgressValue}
                          userProgressValue={userProgressValue}
                          getImage={getImage}
                        />
                      ),
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        padding: "8px",
                        borderBottom: "1px solid lightgray",
                      }}
                    >
                      <Pagination
                        count={mcqPagination.current.numPages}
                        page={mcqPagination.current.page}
                        defaultPage={1}
                        siblingCount={0}
                        onChange={handleMcqPaginationChange}
                        // hidePrevButton
                        // hideNextButton
                      />
                    </Box>
                  </>
                )}
              </Grid>
              {isGraderLoading ? (
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                  gap={1}
                  position="sticky"
                  bottom={0}
                  right={0}
                >
                  {Array(2)
                    .fill("")
                    .map((_, index) => {
                      return (
                        <Skeleton
                          sx={{
                            borderRadius: "4px",
                            minWidth: "150px",
                            minHeight: "60px",
                          }}
                          key={`certificate_btn_loader_${index}`}
                        />
                      );
                    })}
                </Grid>
              ) : (
                <CertificateGenerator
                  setCertificateLoading={setCertificateLoading}
                  handleCourseRefresh={handleCourseRefresh}
                  certificateLoading={certificateLoading}
                />
              )}
            </Grid>
          </Slide>
        </Grid>
        <Grid
          item
          lg={5}
          md={5}
          sm={12}
          xs={12}
          sx={{
            padding: { lg: "1em", md: ".5em", sm: "1em .5em", xs: "1em .5em" },
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            // paddingTop: showTemplate ? "unset" : "1em",
            boxShadow: "0px 6px 16px 0px #0000001F",
            borderRadius: "10px",
            height: "fit-content",
          }}
          cy-data="admin-course-grader-tab-table-container"
        >
          {/* tab ui start */}
          {showTemplate ? (
            <Box
              className="box"
              sx={{
                width: "inherit",
                // boxShadow: 1,
                // borderRadius: "5px",
                // padding: "10px",
              }}
              cy-data="admin-course-grader-tab-container"
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Tabs
                  value={graderTabValue}
                  onChange={(_, newValue) => handleTabChange(newValue)}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                  sx={{
                    "& .MuiTab-root": {
                      minWidth: 0,
                    },
                    marginBottom: "1em",
                  }}
                >
                  <Tab
                    disableRipple
                    value="template"
                    icon={
                      <DragHandleIcon
                        sx={{ fontSize: "2rem", cursor: "grab" }}
                      />
                    }
                    cy-data="admin-course-grader-frq-template-tab"
                    disabled={isGraderLoading}
                  />
                  <Tab
                    disableRipple
                    icon={
                      <GroupIcon sx={{ fontSize: "2rem", cursor: "grab" }} />
                    }
                    value="users"
                    cy-data="admin-course-grader-frq-user-tab"
                    disabled={isGraderLoading}
                  />
                  {frqTemplateImgs.length > 0 && (
                    <Tab
                      disableRipple
                      icon={
                        <ImageIcon sx={{ fontSize: "2rem", cursor: "grab" }} />
                      }
                      value="templateImage"
                      cy-data="admin-course-grader-frq-template-image-tab"
                      disabled={isGraderLoading}
                    />
                  )}
                </Tabs>
                <IconButton
                  onClick={handleCloseTemplateView}
                  title="Clear Template"
                  cy-data="admin-course-grader-template-template-tab-close-btn"
                  sx={{ width: "25px", height: "25px" }}
                  disabled={isGraderLoading}
                >
                  <CloseIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
              </Box>

              {tabContent}
            </Box>
          ) : (
            <UserTableList graderTable={true} itemId={selectedCourse?._id} />
          )}
          {/* tab ui end */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GraderUi;
