import React, { useMemo, memo } from "react";
import { Box, Grid, Slide, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../reduxStore/hooks";
import { DOMAIN_CONFIG_OBJ } from "../../../../../../utils/axiosInstance";

const CourseOverViewCard = memo(
  ({
    label,
    value,
    icon,
    bgColor,
  }: {
    label: string;
    value: number;
    icon: React.ReactNode;
    bgColor: string;
  }) => (
    <Slide in={true} direction="up">
      <Grid
        item
        xs={12}
        sm={10}
        md={4}
        lg={2.8}
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          boxShadow: "var(--admin-course-over-view-shadow)",
          padding: ".5em 1em",
          gap: "1em",
        }}
        cy-data="qbook-admin-course-over-view-card"
      >
        <Box
          sx={{
            background: bgColor,
            color: "#ffffff",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50px",
            minWidth: "50px",
          }}
        >
          {icon}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            width: "100%",
            borderRadius: "5px",
            textAlign: "end",
          }}
        >
          <Typography
            variant="h6"
            color="lightgray"
            sx={{ paddingRight: "10px" }}
          >
            {label}
          </Typography>
          <Typography
            variant="body2"
            color="var(--admin-course-overview-text)"
            sx={{
              paddingRight: "10px",
              marginBottom: "5px",
              fontSize: "20px",
              fontWeight: 700,
            }}
          >
            {value}
          </Typography>
        </Box>
      </Grid>
    </Slide>
  ),
);

const CourseOverView = () => {
  const { courses } = useAppSelector((state) => state.adminCourse);

  const cardData = useMemo(() => {
    const totalCourses = courses?.length;
    const totalClasses = courses?.filter(
      (course) => course.classesRunning,
    )?.length;
    const totalStudents = courses?.reduce(
      (acc, course) =>
        acc +
        (course.userList?.filter((item) => item?.role === "Student").length ||
          0),
      0,
    );

    return DOMAIN_CONFIG_OBJ.admin.courses.courseOverviewCardData.map(
      (data) => {
        switch (data.id) {
          case "crs":
            return { ...data, value: totalCourses };
          case "cls":
            return { ...data, value: totalClasses };
          case "std":
            return { ...data, value: totalStudents };
          default:
            return data;
        }
      },
    );
  }, [courses]);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        gap: "1em",
        justifyContent: "center",
        maxHeight: "300px",
        overflow: "auto",
        padding: "10px 0",
      }}
    >
      {cardData.map((data) => (
        <CourseOverViewCard
          key={data.id}
          bgColor={data.bgColor}
          icon={data.icon}
          label={data.label}
          value={data.value}
        />
      ))}
    </Grid>
  );
};

export default memo(CourseOverView);
