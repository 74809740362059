import {
  Backdrop,
  Box,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { DOMAIN_CONFIG_OBJ } from "../../../../utils/axiosInstance";
import { useAppSelector } from "../../../../reduxStore/hooks";
import { useNavigate } from "react-router-dom";
import {
  QUERY_COURSE,
  QUERY_FILE,
} from "../../../../utils/constants/urlParamsConstants";
import { searchUserQbookQuery } from "../../../../reduxStore/services/authService";
import SchoolIcon from "@mui/icons-material/School";
import blogsIcons from "../../../../assets/img/blog.svg";
import { StyledTextField } from "../../../components/styledComponents/customTextField";
import "./CommonSearch.css";

type Props = {
  open: boolean;
  closeHandler: () => void;
};

type SearchCardType = {
  data: any;
  isBlog: boolean;
};

const SearchCard = ({ data, isBlog }: SearchCardType) => {
  const navigate = useNavigate();
  const { isDark } = useAppSelector((state) => state.theme);
  const onClickHandler = () => {
    if (isBlog) {
      navigate(`/blogs/?${QUERY_FILE}=${data._id}`);
    } else {
      navigate(
        `/learn/?${QUERY_COURSE}=${data._id}&${QUERY_FILE}=${data.content[0]._id}`,
      );
    }
  };
  return (
    <Box
      onClick={() => {
        onClickHandler();
      }}
      display="flex"
      // width="100%"
      maxHeight={100}
      gap={1}
      padding="5px"
      sx={{
        cursor: "pointer",
        borderRadius: "5px",
        ":hover": {
          background: "lightGray",
        },
      }}
      cy-data="search-result-card"
    >
      <Box sx={{ display: "flex", overflow: "hidden", gap: 1 }}>
        {isBlog ? (
          <Icon
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ":hover": {
                transform: "scale(1.5)",
              },
            }}
          >
            <img
              src={blogsIcons}
              height="100%"
              width="100%"
              style={{
                objectFit: "contain",
                filter: isDark ? "invert(1)" : "",
              }}
              alt="blogsIcon"
            />
          </Icon>
        ) : (
          <SchoolIcon />
        )}
        <Typography
          sx={{
            maxWidth: "inherit",
            fontSize: ".875rem",
            fontWeight: 400,
            fontFamily: "var(--global-font-family-secondary)",
            color: "text.primary",
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {isBlog ? data.cardInfo.title : data.courseName}
        </Typography>
      </Box>
    </Box>
  );
};

const CommonSearch = (props: Props) => {
  const { listedPublicCourses } = useAppSelector((state) => state.learn);
  const { blogArray } = useAppSelector((state) => state.blogs);
  const [blogsHolder, setBlogsHolder] = useState<any>([]);
  const [courseHolder, setCourseHolder] = useState<any>([]);
  const [loadingSearchResult, setLoadingSearchResult] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_previousSearchOptions, setPreviousSearchOptions] = useState<
    Array<string>
  >([]);
  const [filteredBlogs, setFilteredBlogs] = useState<any>([]);
  const [filteredCourse, setFilteredCourse] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string | any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inputValue, _setInputValue] = useState<string | any>("");
  const [hideAnimation, setHideAnimation] = useState<boolean>(false);

  const loadCountRef = useRef(0);

  function searchCourses(courseList: Array<any>, query: string) {
    if (query.length < 1) {
      return courseList;
    }
    const resultantCourses = courseList.filter((obj: any) => {
      return Object.values(obj).some((val: any) => {
        if (!Array.isArray(val) && typeof val === "string") {
          return val.toLowerCase().includes(query.toLowerCase());
        }
        return false;
      });
    });
    return resultantCourses;
  }
  function searchBlogs(blogList: Array<any>, query: string) {
    if (query.length < 1) {
      return blogList;
    }
    const resultantCourses = blogList.filter((obj: any) => {
      return Object.values(obj.cardInfo).some((val: any) => {
        if (!Array.isArray(val) && typeof val === "string") {
          return val.toLowerCase().includes(query.toLowerCase());
        }
        return false;
      });
    });
    return resultantCourses;
  }
  async function loadFromApi() {
    // if (!inputValue) {
    //   return;
    // }
    loadCountRef.current += 1;
    let history: string[] = [];
    const qbookSearchHistory = localStorage.getItem("qbookSearch");
    if (qbookSearchHistory) {
      history = [
        ...new Set(qbookSearchHistory.split(",").filter((item) => item)),
      ];
      localStorage.removeItem("qbookSearch");
    }
    history.push(inputValue);
    setPreviousSearchOptions([...new Set(history)]);
    localStorage.setItem("qbookSearch", history.join(","));
    setLoadingSearchResult(true);
    try {
      const response = await searchUserQbookQuery(searchQuery);
      if (response?.data?.blogs) {
        setBlogsHolder(response?.data?.blogs);
      }
      if (response?.data?.courses) {
        setCourseHolder(response?.data?.courses);
      }
    } catch (err) {
      setBlogsHolder(blogArray);
      setCourseHolder(listedPublicCourses);
    } finally {
      setLoadingSearchResult(false);
    }
  }
  const handleResetSearch = () => {
    setBlogsHolder(blogArray);
    setCourseHolder(listedPublicCourses);
    setPreviousSearchOptions([]);
    localStorage.removeItem("qbookSearch");
    loadCountRef.current = 0;
    setSearchQuery("");
  };
  useEffect(() => {
    setBlogsHolder(blogArray);
    setCourseHolder(listedPublicCourses);
  }, [listedPublicCourses, blogArray]);
  useEffect(() => {
    let courses = courseHolder;
    let blogs = blogsHolder;
    courses = searchCourses(courses, searchQuery);
    blogs = searchBlogs(blogs, searchQuery);
    setFilteredCourse(courses);
    setFilteredBlogs(blogs);
  }, [searchQuery, blogsHolder, courseHolder]);

  const handleCloseSearch = () => {
    setHideAnimation(true);
    setTimeout(() => {
      props.closeHandler();
      setHideAnimation(false);
    }, 100);
  };
  // useEffect(() => {
  //   const qbookSearchHistory = localStorage.getItem("qbookSearch");
  //   if (qbookSearchHistory) {
  //     setPreviousSearchOptions([...new Set(qbookSearchHistory.split(","))]);
  //   }
  // }, []);
  return (
    <Modal
      open={props.open}
      onClose={handleCloseSearch}
      aria-labelledby="search-modal-title"
      aria-describedby="search-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            zIndex: 6000,
            background: "#8c959f33",
          },
        },
      }}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingTop: "12px",
        paddingLeft: { xs: "0px", sm: "0px", md: "80px", lg: "135px" },
      }}
    >
      <Grid
        container
        maxWidth="md"
        component={Paper}
        alignItems="flex-start"
        zIndex={6005}
        borderRadius=".75rem"
        maxHeight="80vh"
        display="flex"
        alignContent="flex-start"
        className={!hideAnimation ? "slide-in-top" : "slide-out-top"}
      >
        <Grid item xs={12} padding={1}>
          <StyledTextField
            placeholder={"Search.."}
            fullWidth
            size="small"
            value={searchQuery}
            onChange={(e) => {
              e.preventDefault();
              setSearchQuery(e.target.value);
            }}
            autoFocus
            InputProps={{
              sx: { color: "var(--text-primary-clr2) !important" },
              startAdornment: <SearchIcon />,
              endAdornment: (
                <InputAdornment position="end">
                  {searchQuery.length > 0 && (
                    <IconButton
                      sx={{ padding: "0 !important" }}
                      onClick={handleResetSearch}
                      cy-data="clear-search-input-btn"
                    >
                      <CloseIcon sx={{ fontSize: ".8em" }} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              background: "inherit !important",
              color: "var(--text-primary-clr2) !important",
            }}
            cy-data="common-search-input"
          />
        </Grid>
        <Grid item xs={12} container overflow="auto" maxHeight="600px">
          {searchQuery.length > 0 && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                ":hover": {
                  background: "lightGray",
                },
              }}
              padding={1}
              margin={1}
              borderRadius={1}
              onClick={() => loadFromApi()}
              cy-data="search-all-domain-section"
            >
              <Box maxWidth="80%" display="flex" gap={1}>
                <SearchIcon />
                <Typography variant="body1" color="gray">
                  {searchQuery}
                </Typography>
              </Box>
              <Typography variant="body1" color="gray">
                {`Search All of ${DOMAIN_CONFIG_OBJ.domainName}`}
              </Typography>
            </Grid>
          )}
          {filteredCourse.length > 0 && (
            <Grid
              item
              xs={12}
              overflow="auto"
              padding={1}
              gap={1}
              display="flex"
              flexDirection="column"
              sx={{ borderTop: "1px solid lightgray" }}
            >
              <Typography
                sx={{ fontSize: "12px", fontWeight: 600, color: "#8d96a0" }}
              >
                Courses
              </Typography>

              {loadingSearchResult
                ? Array(filteredCourse.length || 3)
                    .fill("")
                    .map((_, index) => {
                      return (
                        <Skeleton
                          width="100%"
                          height={50}
                          key={`search_skelton_${index}`}
                        />
                      );
                    })
                : filteredCourse
                    .filter((course: any) => course.courseName)
                    .map((item: any) => (
                      <SearchCard data={item} isBlog={false} key={item.title} />
                    ))}
            </Grid>
          )}
          {filteredBlogs.length > 0 && (
            <Grid
              item
              xs={12}
              overflow="auto"
              padding={1}
              gap={1}
              display="flex"
              flexDirection="column"
              sx={{ borderTop: "1px solid lightgray" }}
            >
              <Typography
                sx={{ fontSize: "12px", fontWeight: 600, color: "#8d96a0" }}
              >
                Blogs
              </Typography>
              {loadingSearchResult
                ? Array(filteredBlogs.length || 3)
                    .fill("")
                    .map((_, index) => {
                      return (
                        <Skeleton
                          width="100%"
                          height={50}
                          key={`search_skelton_blog_${index}`}
                        />
                      );
                    })
                : filteredBlogs.map((item: any, index: any) => {
                    return (
                      <SearchCard
                        data={item}
                        isBlog={true}
                        key={`${item.title}_${index}`}
                      />
                    );
                  })}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CommonSearch;
