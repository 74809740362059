import { Alert, IconButton, Slide, Snackbar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../reduxStore/hooks";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { handleSnackbarClose } from "../../../reduxStore/features/snackbarSlice";

const StyledSnackbar = () => {
  const dispatch = useAppDispatch();
  const { open, message, severity, anchorOrigin, autoHideDuration } =
    useAppSelector((state) => state.snackbar);

  useEffect(() => {
    setTimeout(() => {
      dispatch(handleSnackbarClose());
    }, autoHideDuration);
  }, [autoHideDuration, dispatch, open]);

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          dispatch(handleSnackbarClose());
        }}
        cy-data="qbook-styled-snackbar-close-button"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={open}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      TransitionComponent={Slide}
      action={action}
      sx={{ zIndex: 2000, mt: "2rem" }}
      cy-data="qbook-styled-snackbar"
    >
      <Alert severity={severity} cy-data="qbook-styled-snackbar-alert">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default StyledSnackbar;
