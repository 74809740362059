import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  loadSingleCourseDataAction,
  retrieveAdminCoursesAction,
  retrieveStudentsSolutionInCourseForFreeResponseAction,
  retrieveStudentsSolutionInCourseForMcqAction,
} from "../middleware/adminCourseMiddleware";
import { CourseType } from "../types/LearnTypes";
import _ from "lodash";

export type CourseUser = {
  userId: string;
  userName: string;
  progress: string;
  lastActivity: string;
  role: string;
  frontEndId: string;
  email: string;
  firstName: string;
  lastName: string;
  isCertificateSent?: boolean;
};

export interface AdminCourseState {
  loadingAdminCourses: boolean;
  courses: Array<CourseType>;
  refreshingCourses: Array<any>;
  selectedCourse: any;
  selectedCourseUserList: Array<CourseUser>;
  selectedUser: CourseUser;
  showGrader: boolean;
}
const userPlaceHolder = {
  userId: "",
  userName: "Loading..",
  progress: "00",
  lastActivity: "Loading..",
  role: "Loading..",
  frontEndId: "test1",
  email: "Loading..",
  firstName: "Loading..",
  lastName: "Loading..",
};
const initialState: AdminCourseState = {
  loadingAdminCourses: false,
  courses: [],
  refreshingCourses: [],
  selectedCourse: undefined,
  showGrader: false,
  selectedCourseUserList: [userPlaceHolder],
  selectedUser: userPlaceHolder,
};

const adminCourseSlice = createSlice({
  name: "adminCourse",
  initialState: initialState,
  reducers: {
    setSelectedCourse: (state, { payload }) => {
      const { courseId } = payload;
      const selectedCourse = state.courses.filter(
        (course) => course._id === courseId,
      )[0];
      const selectedCourseUserList = selectedCourse?.userList
        ?.filter((item) => item !== null && item !== undefined)
        ?.map((user: any, index: number) => ({
          ...user,
          frontEndId: `user_${selectedCourse?._id}_${index}`,
        }));
      const selectedUser = selectedCourseUserList[0];
      state.selectedCourse = selectedCourse;
      state.selectedCourseUserList = selectedCourseUserList;
      state.selectedUser = selectedUser;
    },
    clearSelectedCourse: (state, _) => {
      state.selectedCourse = undefined;
      state.selectedCourseUserList = [userPlaceHolder];
      state.selectedUser = userPlaceHolder;
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
    setShowGrader: (state, { payload }: { payload: boolean }) => {
      state.showGrader = payload;
    },
    addCourseIdInToRefreshingCourses: (state, { payload }) => {
      const { courseId } = payload;
      state.refreshingCourses = [
        ...new Set([...state.refreshingCourses, courseId]),
      ];
    },
    removeCourseIdFromRefreshingCourses: (state, { payload }) => {
      const { courseId } = payload;
      state.refreshingCourses = state.refreshingCourses.filter(
        (id) => id !== courseId,
      );
    },
  },
  extraReducers(builder) {
    // retrieve all courses that user is a collaborator
    builder.addCase(retrieveAdminCoursesAction.pending, (state) => {
      state.loadingAdminCourses = true;
    });
    builder.addCase(
      retrieveAdminCoursesAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        const sortedCourses = _.orderBy(
          action.payload,
          [(item: any) => item?.createdAt],
          ["desc"],
        );
        state.courses = sortedCourses || [];
        let firstCourse = sortedCourses[0] || undefined;
        // if a course is being selected in redux state, update that course
        // if not select the first course
        if (state?.selectedCourse?._id) {
          firstCourse = sortedCourses.find(
            (c: CourseType) => c._id === state?.selectedCourse?._id,
          );
        }
        state.selectedCourse = firstCourse;
        if (firstCourse) {
          const formattedUserArray = firstCourse?.userList
            ?.filter((item: any) => item !== null && item !== undefined)
            ?.map((user: any, index: number) => ({
              ...user,
              frontEndId: `user_${firstCourse?._id}_${index}`,
            }));
          state.selectedCourseUserList = formattedUserArray;
          let selectedUser = formattedUserArray[0];
          if (state?.selectedUser?.frontEndId) {
            selectedUser = formattedUserArray.find(
              (user: any) =>
                user.frontEndId === state?.selectedUser?.frontEndId,
            );
          }
          state.selectedUser = selectedUser;
        }
        state.loadingAdminCourses = false;
      },
    );
    builder.addCase(retrieveAdminCoursesAction.rejected, (state, action) => {
      state.loadingAdminCourses = false;
      state.courses = [];
      state.selectedCourse = undefined;
      state.selectedCourseUserList = [userPlaceHolder];
      state.selectedUser = userPlaceHolder;
    });
    builder.addCase(
      retrieveStudentsSolutionInCourseForFreeResponseAction.pending,
      (state) => {},
    );
    builder.addCase(
      retrieveStudentsSolutionInCourseForFreeResponseAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(
      retrieveStudentsSolutionInCourseForFreeResponseAction.rejected,
      (state, action) => {},
    );
    // getting all mcq responses submitted by user
    builder.addCase(
      retrieveStudentsSolutionInCourseForMcqAction.pending,
      (state) => {},
    );
    builder.addCase(
      retrieveStudentsSolutionInCourseForMcqAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(
      retrieveStudentsSolutionInCourseForMcqAction.rejected,
      (state, action) => {},
    );
    // loading single course data
    builder.addCase(loadSingleCourseDataAction.pending, (state) => {});
    builder.addCase(
      loadSingleCourseDataAction.fulfilled,
      (state, { payload }) => {
        const { courseObj } = payload || {};
        if (!courseObj) return;

        const courseId = courseObj._id;
        const course = state.courses.find((c) => c._id === courseId);
        if (course) {
          Object.assign(course, courseObj);
        } else {
          console.warn(`Course with id ${courseId} not found`);
        }

        const previousSelectedUserId = state.selectedUser?.frontEndId;
        const formattedUserArray =
          courseObj.userList
            ?.filter((item: any) => item !== null && item !== undefined)
            ?.map((user: any, index: number) => ({
              ...user,
              frontEndId: `user_${courseObj._id}_${index}`,
            })) || [];

        Object.assign(state, {
          selectedCourse: courseObj,
          selectedCourseUserList: formattedUserArray,
          selectedUser:
            formattedUserArray.find(
              (user: any) => user.frontEndId === previousSelectedUserId,
            ) || null,
        });
      },
    );
    builder.addCase(loadSingleCourseDataAction.rejected, (state, action) => {});
  },
});

export const {
  setSelectedCourse,
  setShowGrader,
  addCourseIdInToRefreshingCourses,
  removeCourseIdFromRefreshingCourses,
  clearSelectedCourse,
  setSelectedUser,
} = adminCourseSlice.actions;
export default adminCourseSlice.reducer;
