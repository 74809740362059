import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import CustomSearchField from "../../../../components/styledComponents/customTextField";
import BlogProfileCard from "./components/BlogProfileCard";
import likeIcon from "../../../../../assets/img/upvoteHeart.png";
import readIcon from "../../../../../assets/img/view-4848045-4042325.webp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DeleteIcon from "@mui/icons-material/Delete";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { DOMAIN_CONFIG_OBJ } from "../../../../../utils/axiosInstance";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../reduxStore/hooks";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import {
  deleteBlogsAction,
  loadSubmittedBlogsAction,
} from "../../../../../reduxStore/middleware/adminBlogsMiddleware";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ThreeCircles } from "react-loader-spinner";

type Props = {};

export const BlogAccordion = ({ blog }: { blog: any }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deletingBlog, setDeletingBlog] = useState(false);
  const handleViewBlog = () => {
    navigate(`/blogs/?file=${blog._id}`);
  };
  const handleDeleteBlog = () => {
    Swal.fire({
      title: "Are you sure to delete this blog?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes Continue",
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setDeletingBlog(true);
        dispatch(deleteBlogsAction({ blogId: blog._id })).then((data) => {
          if (data.type.includes("fulfilled")) {
            Swal.fire("Deleted!", "", "success");
          } else {
            Swal.fire("Failed to delete the blog!", "", "error");
          }
          setDeletingBlog(false);
        });
      }
    });
  };
  return (
    <Accordion
      sx={{
        margin: "1em",
        boxShadow: 3,
        borderRadius: "10px",
        ":before": {
          background: "transparent",
        },
        position: "relative",
      }}
      cy-data="admin-blog-dashboard-accordion"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon cy-data="accordion-expand-icon" />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Grid container gap={1}>
          <Grid item xs={2}>
            <img
              src={blog?.cardInfo?.imageLink || DOMAIN_CONFIG_OBJ.simpleLogo}
              alt="blogImg"
              width={50}
              height={50}
              style={{
                objectFit: "contain",
                overflow: "hidden",
                borderRadius: "5px",
                border: "1px solid lightgray",
              }}
            />
          </Grid>
          <Grid item container xs={8} pl={{ xs: 1 }}>
            <Typography variant="h6" cy-data="admin-blog-accordion-title">
              {blog.cardInfo.title}
            </Typography>

            <Grid
              item
              container
              xs={12}
              sx={{
                color: "#7b7b7b",
                fontWeight: 600,
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  gap: "1em",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BookmarkIcon />
                Bookmarks : {blog?.cardInfo?.bookmarkedBy?.length || 0}
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  gap: "1em",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: { lg: "5px", md: "0", sm: "0", xs: "0" },
                }}
              >
                <FavoriteIcon />
                Likes : {blog?.cardInfo?.upvotersArray?.length || 0}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{ display: "flex", flexDirection: "column", gap: "1em" }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 700 }}
          cy-data="admin-blog-accordion-description"
        >
          Description : {blog.cardInfo.description || "No description provided"}
        </Typography>
        <Typography variant="body1">
          Published at :{" "}
          {blog?.cardInfo?.datePublished
            ? Object.values(blog.cardInfo.datePublished)[0]
            : "N/A"}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1em" }}>
          <Button
            variant="qbookStyledBtn"
            color="error"
            title="Delete Blog"
            sx={{ height: "26px" }}
            onClick={() => handleDeleteBlog()}
            cy-data="admin-blogs-accordion-delete"
          >
            <DeleteIcon />
          </Button>
          <Button
            variant="qbookStyledBtn"
            color="success"
            title="View Blog"
            sx={{ height: "26px" }}
            onClick={() => handleViewBlog()}
            cy-data="admin-blogs-accordion-view"
          >
            <VisibilityIcon />
          </Button>
        </Box>
      </AccordionDetails>
      <Dialog
        open={deletingBlog}
        disablePortal
        sx={{
          position: "absolute",
          ":root": { position: "absolute" },
          "& .MuiBackdrop-root": {
            position: "absolute",
          },
          "& .MuiDialog-root": {
            position: "absolute",
          },
        }}
      >
        <DialogContent>
          <Stack flexDirection="row" gap={1}>
            <ThreeCircles
              height="30"
              width="30"
              color={"var(--accent-clr2)"}
              ariaLabel="grid-loading"
              wrapperClass=""
              visible
            />
            <Typography fontSize={16} color="text.primary">
              Please Wait, Deleting Blog.
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </Accordion>
  );
};

const BlogDataCard = ({
  title,
  value,
  icon,
}: {
  title: string;
  value: number;
  icon: any;
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={3}
      lg={3}
      sx={{
        boxShadow: 3,
        borderRadius: "10px",
        padding: "1em",
        placeSelf: "flex-end",
      }}
      cy-data="blog-dashboard-data-card"
    >
      <img src={icon} alt="blogDataImg" width={50} height={50} />
      <Typography
        sx={{
          fontSize: "20px",
          color: "#7b7b7b",
        }}
      >{`${title} : ${value}`}</Typography>
    </Grid>
  );
};

const BlogDashBoard = (props: Props) => {
  useDocumentTitle(`Admin | Blogs | ${DOMAIN_CONFIG_OBJ.domainName}`);
  const dispatch = useAppDispatch();
  const { adminBlogArray, loadingAdminBlogs } = useAppSelector(
    (state) => state.adminBlogs,
  );
  const [blogData, setBlogData] = useState(adminBlogArray || []);
  const [totalViews, setTotalViews] = useState<number>(0);
  const [totalUpvotes, setTotalUpvotes] = useState<number>(0);
  const [tabValue, setTabValue] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const handleTabChange = (e: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };
  const loadAllBlogs = async () => {
    await dispatch(loadSubmittedBlogsAction());
  };
  function searchBlogs(blogList: Array<any>, query: string) {
    if (query.length < 1) {
      return blogList;
    }
    const resultantBlogs = blogList.filter((obj) =>
      obj?.cardInfo?.title?.includes(query),
    );
    return resultantBlogs;
  }

  function sortBlogs(items: Array<any>) {
    switch (tabValue) {
      case 1:
        return _.orderBy(items, [(item: any) => item.createdAt], ["asc"]);
      case 2:
        return _.orderBy(
          items,
          [(item: any) => item?.cardInfo?.upvotersArray?.length],
          ["desc"],
        );
      default:
        return items;
    }
  }
  function calculateTotalVotesAndView() {
    if (adminBlogArray.length === 0) {
      setTotalUpvotes(0);
      setTotalViews(0);
      return;
    }
    let viewCount = 0;
    let voteCount = 0;
    // eslint-disable-next-line array-callback-return
    adminBlogArray.map((blog: any) => {
      if (blog?.cardInfo?.numberUpvotes) {
        voteCount += blog?.cardInfo?.numberUpvotes;
      }
      if (blog?.cardInfo?.numberViews) {
        viewCount += blog?.cardInfo?.numberViews;
      }
    });
    setTotalUpvotes(voteCount);
    setTotalViews(viewCount);
  }
  useEffect(() => {
    loadAllBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculateTotalVotesAndView();
    let filteredItems = adminBlogArray || [];
    filteredItems = searchBlogs(filteredItems, searchQuery);
    filteredItems = sortBlogs(filteredItems);
    setBlogData(filteredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminBlogArray, searchQuery, tabValue]);

  return (
    <Grid
      container
      padding={{ lg: "2rem", md: "1em", sm: ".5em", xs: "10px" }}
      gap="1rem"
    >
      <Grid item container xs={12} justifyContent="space-between">
        <Typography
          variant="h6"
          sx={{
            color: "gray",
            fontWeight: 900,
          }}
        >
          {DOMAIN_CONFIG_OBJ.admin.blogs.welcomeText}
        </Typography>
      </Grid>
      <Grid item container xs={12} gap={1} padding={1}>
        <Grid item container xs={12} sm={12} md={4.5} lg={4.5}>
          <BlogProfileCard />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={7}
          lg={7}
          gap={1}
          justifyContent="flex-end"
          overflow="auto"
          padding={1}
        >
          <BlogDataCard title="Upvotes" value={totalUpvotes} icon={likeIcon} />
          <BlogDataCard title="Views" value={totalViews} icon={readIcon} />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        gap={1}
        columnSpacing={1}
      >
        {/* <Grid item xs={12} sm={12} md={12} lg={5.5} maxHeight={300}>
          <Typography variant="h6" marginBottom={1}>
            Blog view status
          </Typography>
          <BlogChart />
        </Grid> */}
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={8}
          flexWrap="wrap"
          maxWidth="md"
        >
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            padding={{ xs: "2em 0" }}
            gap={1}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="nav tabs example"
                role="navigation"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "var(--accent-clr2)",
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: "var(--accent-clr2)",
                  },
                }}
              >
                <Tab
                  label="All"
                  sx={{ textTransform: "none" }}
                  cy-data="admin-blog-dashboard-tab"
                />
                <Tab
                  label="Recent"
                  sx={{ textTransform: "none" }}
                  cy-data="admin-blog-dashboard-tab"
                />
                <Tab
                  label="Most Liked"
                  sx={{ textTransform: "none" }}
                  cy-data="admin-blog-dashboard-tab"
                />
              </Tabs>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CustomSearchField
                placeHolder="Search Blogs"
                value={searchQuery}
                handler={setSearchQuery}
                cyData="admin-blog-dashboard-search"
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              maxHeight: "300px",
              minHeight: "200px",
              overflowX: "hidden",
              overflowY: "auto",
              padding: { lg: "1em" },
            }}
          >
            {loadingAdminBlogs ? (
              <Stack
                flexDirection="row"
                gap={1}
                boxShadow={3}
                borderRadius="10px"
                padding="25px 15px"
              >
                <ThreeCircles
                  height="30"
                  width="30"
                  color={"var(--accent-clr2)"}
                  ariaLabel="grid-loading"
                  wrapperClass=""
                  visible
                />
                <Typography variant="body1" color="var(--text-primary-clr1)">
                  Loading Blogs please wait...
                </Typography>
              </Stack>
            ) : !blogData.length && !searchQuery.length ? (
              <Typography variant="body1" color="var(--text-gray2)">
                No blogs found
              </Typography>
            ) : !blogData.length && searchQuery.length ? (
              <Typography variant="body1" color="var(--text-gray2)">
                Sorry unable to find the blog, you were looking for
              </Typography>
            ) : (
              blogData?.map((blog, index) => {
                return (
                  <BlogAccordion blog={blog} key={`blog-accordion-${index}`} />
                );
              })
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlogDashBoard;
