import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BlogInfoType, BlogType } from "../types/BlogTypes";
import { JupyterFile } from "../types/FileTypes";
import {
  bookmarkBlogAction,
  copyBlogAction,
  getBlogAction,
  getBookmarkedBlogAction,
  getMoreBlogsAction,
  upvoteBlogAction,
} from "../middleware/blogsMiddleware";

export interface BlogStoreState {
  blogInfo: BlogInfoType;
  blogMetaData: JupyterFile[] | null | any;
  blogLoading: Boolean;
  blogLoadingFailed: Boolean;
  blogLoadingSuccess: Boolean;
  blogArray: Array<BlogType>;
  blogPages: number;
  userBookmarkBlogs: Array<BlogType>;
  bookmarkedBlogPage: number;
  pageNumber: number;
  bookmarkedPageNumber: number;
}

const initialState: BlogStoreState = {
  blogInfo: {
    title: "",
    description: "",
    userName: "",
    datePublished: "",
    numberUpvotes: 0,
    numberViews: 0,
    filePath: "",
    imageLink: "",
    tags: [],
    bookmarkedBy: [],
    upvotersArray: [],
  },
  blogMetaData: [],
  blogLoading: true,
  blogLoadingFailed: false,
  blogLoadingSuccess: true,
  blogArray: [],
  blogPages: 0,
  userBookmarkBlogs: [],
  bookmarkedBlogPage: 0,
  pageNumber: 0,
  bookmarkedPageNumber: 0,
};
var blogDataToMetadata = (blogData: JupyterFile) => {
  /*
   * Parse the blog jupyter notebook data into a metadata object.
   */
  blogData.cells = blogData.cells.map((cell, index) => {
    // maps cell indices so we can edit objects and save to filesystem cell-by-cell
    let metadata = cell.metadata;
    if (!metadata) {
      metadata = {};
    }
    metadata["cell_index"] = index;
    cell.metadata = metadata;
    return cell;
  });
  return blogData;
};

const blogSlice = createSlice({
  name: "blogs",
  initialState: initialState,
  reducers: {
    setBlogLoadState: (state, { payload }) => {
      state.blogLoading = payload;
    },
    clearUserBookmarkedBlogs: (state) => {
      state.userBookmarkBlogs = [];
    },
    setPageNumber: (state, { payload }) => {
      state.pageNumber = payload;
    },
    setBookmarkedPageNumber: (state, { payload }) => {
      state.bookmarkedPageNumber = payload;
    },
  },
  extraReducers: (builder) => {
    // get single blog
    builder.addCase(getBlogAction.pending, (state) => {
      state.blogLoading = true;
      state.blogLoadingFailed = false;
      state.blogLoadingSuccess = false;
    });
    builder.addCase(
      getBlogAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        let { blogInfo, blogData } = action.payload;
        // We intialize the article to be rendered.
        let blogMetaData = null;
        if (blogData === "") {
          blogData = undefined;
        }
        if (blogData !== undefined) {
          blogMetaData = blogDataToMetadata(JSON.parse(blogData));
        }
        state.blogInfo = blogInfo;
        state.blogMetaData = blogMetaData;
        state.blogLoading = false;
        state.blogLoadingFailed = false;
        state.blogLoadingSuccess = true;
      },
    );
    builder.addCase(getBlogAction.rejected, (state, action) => {
      state.blogMetaData = undefined;
      state.blogLoading = false;
      state.blogLoadingFailed = true;
      state.blogLoadingSuccess = false;
    });
    // get more blogs
    builder.addCase(getMoreBlogsAction.pending, (state) => {});
    builder.addCase(
      getMoreBlogsAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { blogsArray, numPages } = action.payload;
        state.blogArray = blogsArray;
        state.blogPages = numPages;
      },
    );
    builder.addCase(getMoreBlogsAction.rejected, (state, action) => {});
    // upvote blog
    builder.addCase(upvoteBlogAction.pending, (state) => {});
    builder.addCase(
      upvoteBlogAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(upvoteBlogAction.rejected, (state, action) => {});
    // bookmark blog
    builder.addCase(bookmarkBlogAction.pending, (state) => {});
    builder.addCase(
      bookmarkBlogAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        const blogIndex = state.blogArray.findIndex(
          (blog) => blog._id === action.payload.blogId,
        );
        if (blogIndex !== -1) {
          if (action.payload.isAdded) {
            // add user id to the appropriate blog
            const bookmarkedUserArray: Array<string> =
              state.blogArray[blogIndex].cardInfo.bookmarkedBy;
            bookmarkedUserArray.push(action.payload.userId);
            state.blogArray[blogIndex].cardInfo.bookmarkedBy =
              bookmarkedUserArray;
            if (state.blogInfo.title) {
              state.blogInfo.bookmarkedBy = bookmarkedUserArray;
            }
          } else {
            // remove user id from the appropriate blog
            const bookmarkedUserArray: Array<string> = state.blogArray[
              blogIndex
            ].cardInfo.bookmarkedBy.filter(
              (userId) => userId !== action.payload.userId,
            );
            state.blogArray[blogIndex].cardInfo.bookmarkedBy =
              bookmarkedUserArray;
            if (state.blogInfo.title) {
              state.blogInfo.bookmarkedBy = bookmarkedUserArray;
            }
          }
        }
      },
    );
    builder.addCase(bookmarkBlogAction.rejected, (state, action) => {});
    // copy blog
    builder.addCase(copyBlogAction.pending, (state) => {});
    builder.addCase(
      copyBlogAction.fulfilled,
      (state, action: PayloadAction<any>) => {},
    );
    builder.addCase(copyBlogAction.rejected, (state, action) => {});
    builder.addCase(getBookmarkedBlogAction.fulfilled, (state, action) => {
      const { blogsArray, numPages } = action.payload;
      state.userBookmarkBlogs = blogsArray;
      state.bookmarkedBlogPage = numPages;
    });
    builder.addCase(getBookmarkedBlogAction.rejected, (state) => {
      state.userBookmarkBlogs = [];
    });
  },
});

export const {
  setBlogLoadState,
  clearUserBookmarkedBlogs,
  setPageNumber,
  setBookmarkedPageNumber,
} = blogSlice.actions;
export default blogSlice.reducer;
