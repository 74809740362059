import React, { useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../reduxStore/hooks";
import { Box, Typography } from "@mui/material";
import CourseCard from "./courseCard";
import CourseTable from "./courseTable";
import { CourseCardLoader, CourseUserTableLoader } from "./CourseLoaders";
import { loadSingleCourseDataAction } from "../../../../../../reduxStore/middleware/adminCourseMiddleware";
import { addCourseIdInToRefreshingCourses } from "../../../../../../reduxStore/features/adminCourseSlice";

type Props = {};

const NoPreviewBox: React.FC = React.memo(() => (
  <Box
    sx={{
      width: "100%",
      minHeight: "217px",
      maxHeight: "217px",
      boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.11)",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      gap: "1em",
      padding: "1.5em",
    }}
  >
    <Typography
      sx={{
        fontSize: "22px",
        color: "#858585",
        fontWeight: 700,
      }}
    >
      No preview available
    </Typography>
  </Box>
));

const CoursePreviewCard: React.FC<Props> = React.memo(() => {
  const dispatch = useAppDispatch();
  const { selectedCourse, loadingAdminCourses } = useAppSelector(
    (state) => state.adminCourse,
  );

  const handleRefresh = useCallback(async () => {
    if (selectedCourse) {
      dispatch(
        addCourseIdInToRefreshingCourses({ courseId: selectedCourse._id }),
      );
      await dispatch(
        loadSingleCourseDataAction({ courseId: selectedCourse._id }),
      );
    }
  }, [dispatch, selectedCourse]);

  if (loadingAdminCourses) {
    return (
      <>
        <CourseCardLoader selectedView={true} />
        <CourseUserTableLoader />
      </>
    );
  }

  if (selectedCourse) {
    return (
      <>
        <CourseCard course={selectedCourse} selectedView={true} />
        {selectedCourse.userList && (
          <CourseTable
            handleRefresh={handleRefresh}
            itemId={selectedCourse._id}
          />
        )}
      </>
    );
  }

  return <NoPreviewBox />;
});

export default CoursePreviewCard;
