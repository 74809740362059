import React, { useState, useRef, useEffect } from "react";
import {
  Cropper,
  CropperPreview,
  CropperPreviewRef,
} from "react-advanced-cropper";
import { Stack } from "@mui/material";
import { DeleteOutline, RestartAlt } from "@mui/icons-material";
import Navigation from "./components/navigation";
import { Slider } from "./components/slider";
import AdjustablePreviewBackground from "./components/adjustablePreviewBackground";
import AdjustableCropperBackground from "./components/adjustableCroppperBackground";
import Button from "./components/button";
import "react-advanced-cropper/dist/style.css";
import "./index.css";
import { AdjustmentTypes, ModeTypes } from "../../../../types";

type PropTypes = {
  photo: string | undefined;
  setPhoto: (value: string) => void;
  deleteAndReset: () => void;
};

const ImageEditor = ({ photo, setPhoto, deleteAndReset }: PropTypes) => {
  const cropperRef = useRef<any | null>(null);
  const previewRef = useRef<CropperPreviewRef>(null);
  const [src, setSrc] = useState<string | undefined>(photo);
  const [mode, setMode] = useState<ModeTypes>("crop");
  const [adjustments, setAdjustments] = useState<AdjustmentTypes>({
    brightness: 0,
    hue: 0,
    saturation: 0,
    contrast: 0,
  });

  useEffect(() => {
    onUpdate();
  }, []);

  const onChangeValue = (value: string) => {
    if (mode in adjustments) {
      setAdjustments((previousValue) => ({
        ...previousValue,
        [mode]: value,
      }));
    }
  };

  const onReset = () => {
    setMode("crop");
    setAdjustments({
      brightness: 0,
      hue: 0,
      saturation: 0,
      contrast: 0,
    });
  };

  const onUpload = (blob: string) => {
    onReset();
    setMode("crop");
    setSrc(blob);
  };

  const onDownload = () => {
    if (cropperRef.current) {
      setPhoto(cropperRef.current.getCanvas()?.toDataURL());
    }
  };

  const onUpdate = () => {
    previewRef.current?.refresh();
  };

  const changed = Object.values(adjustments).some((el) => Math.floor(el * 100));

  const cropperEnabled = mode === "crop";

  return (
    <div className="image-editor">
      <div className="image-editor__cropper">
        <Cropper
          src={src}
          ref={cropperRef}
          stencilProps={{
            movable: cropperEnabled,
            resizable: cropperEnabled,
            lines: cropperEnabled,
            handlers: cropperEnabled,
            overlayClassName: `image-editor__cropper-overlay ${
              !cropperEnabled && "image-editor__cropper-overlay--faded"
            }`,
          }}
          backgroundWrapperProps={{
            scaleImage: cropperEnabled,
            moveImage: cropperEnabled,
          }}
          backgroundComponent={AdjustableCropperBackground}
          backgroundProps={adjustments}
          onUpdate={onUpdate}
        />
        {mode !== "crop" && (
          <Slider
            className="image-editor__slider"
            value={adjustments[mode]}
            onChange={onChangeValue}
          />
        )}
        <CropperPreview
          className="image-editor__preview"
          ref={previewRef}
          cropper={cropperRef}
          // modified version of react advanced cropper, thus ignoring typescript evaluations
          // @ts-ignore
          backgroundComponent={AdjustablePreviewBackground}
          backgroundProps={adjustments}
        />
        <Stack position="absolute" right={20} top={20} gap={1}>
          <Button
            className="image-editor__reset-button"
            title="Delete"
            onClick={deleteAndReset}
          >
            <DeleteOutline />
          </Button>
          <Button
            className={`image-editor__reset-button ${
              !changed && "image-editor__reset-button--hidden"
            }`}
            title="Reset"
            onClick={onReset}
          >
            <RestartAlt />
          </Button>
        </Stack>
      </div>
      <Navigation
        mode={mode}
        onChange={setMode}
        onUpload={onUpload}
        onDownload={onDownload}
      />
    </div>
  );
};

export default ImageEditor;
