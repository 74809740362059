/**
@fileoverviewThis file contains the Profile Details component for the qBraid platform.
It includes the functionality for displaying user details, handling account deletion, and OTP verification.
@description The Profile Details component includes:
Displaying the user's profile image and name
Handling the account deletion process, including confirmation dialog and OTP verification
Displaying appropriate warnings and information about the account deletion process
@todo
Improve the account deletion process by adding more robust error handling and user feedback
Refactor the OTP verification process to improve security and user experience
Copyright (C) 2024 qBraid Development Team 
*/
import { ChangeEvent, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Dialog,
  Divider,
  DialogActions,
  DialogTitle,
  DialogContent,
  Stack,
  IconButton,
  Avatar,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AdminPanelSettings,
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  ManageAccounts,
  Warning,
  CameraAlt,
  EditRounded,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import OtpSuccessDialog from "./components/otpSuccess";
import ImageEditor from "./components/imageEditor";
import "./index.css";
import { ErrorTypes, InputTypes, ModalTypes, OtpErrorTypes } from "../../types";
import { UserData } from "../../../../../reduxStore/types/AuthTypes";
import {
  deleteAccountAsync,
  deleteAccountOTPAsync,
  updatePasswordAsync,
  verifyOTPAsync,
} from "../../../../../reduxStore/services/authService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../reduxStore/hooks";
import { handleSnackbarOpen } from "../../../../../reduxStore/features/snackbarSlice";
import { signOutUser } from "../../../../../reduxStore/middleware/authMiddleware";
import { DOMAIN_CONFIG_OBJ } from "../../../../../utils/axiosInstance";

const UPLOAD_SIZE_LIMIT = 1000; // size of image set to the limit of 1MB

const INITIAL_UDPATE_PASSWORD_VALUES = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};
const updatePasswordValidationSchema = Yup.object({
  currentPassword: Yup.string()
    .min(8, "Must be 8 characters or more")
    .required("Password is required"),
  newPassword: Yup.string()
    .notOneOf(
      [Yup.ref("currentPassword")],
      "The new password can't be the same as current password",
    )
    .min(8, "Must be 8 characters or more")
    .required("Password is required")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .min(8, "Must be 8 characters or more")
    .required("Password is required")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number"),
});

// sets the validation schema for form checks
const urlValidationSchema = Yup.object().shape({
  githubUrl: Yup.string().matches(
    /(?:(?:https?:\/\/(?:www\.)?)|(?:www\.)?)github\.com\/[a-zA-Z0-9_-]+/i,
    "Please enter a valid GitHub URL",
  ),
  linkedInUrl: Yup.string().matches(
    /(?:(?:https?:\/\/(?:www\.)?)|(?:www\.)?)linkedin\.com\/in\/[a-zA-Z0-9_-]+/i,
    "Please enter a valid LinkedIn URL",
  ),
  googleScholarUrl: Yup.string().matches(
    /(?:(?:https?:\/\/(?:www\.)?)|(?:www\.)?)scholar\.google\.com\/citations\?user=[a-zA-Z0-9_-]+/i,
    "Please enter a valid Google Scholar URL",
  ),
  twitterUrl: Yup.string().matches(
    /(?:(?:https?:\/\/(?:www\.)?)|(?:www\.)?)twitter\.com\/[a-zA-Z0-9_-]+/i,
    "Please enter a valid Twitter URL",
  ),
});

type PropTypes = {
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  inputData: InputTypes;
  updateProfile: () => void;
  userDetails: UserData | undefined;
  nameError: string;
  errors: ErrorTypes;
  setErrors: React.Dispatch<React.SetStateAction<ErrorTypes>>;
  isSubmitting: boolean;
  setSelectedImage: Function;
};

/**
 * ProfileDetails component
 * @function ProfileDetails
 * @param {Object} props - props
 * @param {Function} props.handleChange - function to handle change
 * @param {Object} props.inputData - input data
 * @param {Function} props.updateProfile - function to update profile
 * @param {Object} props.userDetails - user details
 * @param {String} props.nameError - name error
 * @param {Object} props.errors - errors
 * @param {Function} props.setErrors - function to set errors
 * @param {Boolean} props.isSubmitting - is submitting
 * @param {Function} props.snackbar - snackbar
 * @param {Function} props.setSelectedImage - function to set selected image
 * @returns {JSX.Element}
 */

const ProfileDetails = ({
  handleChange,
  inputData,
  updateProfile,
  userDetails,
  nameError,
  errors,
  setErrors,
  isSubmitting,
  setSelectedImage,
}: PropTypes) => {
  const theme = useTheme();
  const { user: cognitoUser } = useAppSelector((state) => state.auth);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedModal, setSelectedModal] = useState<ModalTypes>("");
  const [userDeleteLoading, setUserDeleteLoading] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<OtpErrorTypes>(null);
  const [otpModal, setOtpModal] = useState<boolean>(false);
  const [usrImg, setUsrImg] = useState<string>();
  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  // for update password
  const formik = useFormik({
    initialValues: INITIAL_UDPATE_PASSWORD_VALUES,
    validationSchema: updatePasswordValidationSchema,
    onSubmit: async (values) => {
      const { currentPassword, newPassword } = values;
      updatePasswordAsync(cognitoUser, currentPassword, newPassword)
        .then((res) => {
          if (res.toLowerCase() === "success") {
            dispatch(
              handleSnackbarOpen({
                message: "Password updated successfully",
                severity: "success",
              }),
            );
            handleClose();
          }
        })
        .catch((err) => {
          if (err.name === "NotAuthorizedException") {
            dispatch(
              handleSnackbarOpen({
                message: err?.message?.includes("does not have required scope")
                  ? "Password cannot be changed for Google sign in users!"
                  : err?.message,
                severity: "error",
              }),
            );
            handleClose();
          } else {
            dispatch(
              handleSnackbarOpen({
                message: "Password not updated! Please try again later.",
                severity: "error",
              }),
            );
          }
        });
    },
  });

  // toggles visibility for password fields in Udpate password modal
  const showCurrentPasswordHandler = () =>
    setShowCurrentPassword((prev) => !prev);
  const showNewPasswordHandler = () => setShowNewPassword((prev) => !prev);
  const showConfirmPasswordHandler = () =>
    setShowConfirmPassword((prev) => !prev);

  // closes the modal
  const handleClose = () => {
    formik.resetForm();
    setIsOpen(false);
  };

  // sets the modal open with the selected modal
  const handleModalSelection = (modalType: ModalTypes) => {
    setSelectedModal(modalType);
    setIsOpen(true);
  };

  // updates the useImg state if profile photo already available from the api
  useEffect(() => {
    if (userDetails?.personalInformation?.profilePhoto) {
      setUsrImg(userDetails?.personalInformation?.profilePhoto);
    }
  }, [userDetails?.personalInformation?.profilePhoto]);

  // if usrImg is changed, then also change the selectedImage state of parent component
  useEffect(() => {
    setSelectedImage(usrImg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usrImg]);

  // closes the OTP modal
  const handleCloseOtp = () => {
    setOtpModal(false);
  };

  const resendUserOTP = async () => {
    deleteAccountOTPAsync().catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.message,
      });
    });
  };

  const deleteAccount = async () => {
    deleteAccountAsync()
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: `We've deleted your account successfully.`,
          }).then(() => {
            dispatch(
              signOutUser({
                avoidClearingCookies: false,
                logoutRedirect: true,
              }),
            );
            // navigate("/account/delete/survey");
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const verifyUserOTP = async (value: string) => {
    try {
      setUserDeleteLoading(true);
      const response = await verifyOTPAsync(value);
      if (response.status === 200) {
        // makes a api call for deleting the account
        await deleteAccount();
        setUserDeleteLoading(false);
      }
      setOtpModal(false);
    } catch (err: any) {
      setOtpError({
        error: true,
        helperText: err?.response?.data?.message,
      });
      setUserDeleteLoading(false);
    }
  };

  const handleDeleteAccountConfirmation = () => {
    handleClose();
    Swal.fire({
      icon: "warning",
      iconColor: "red",
      title: "Are you sure?",
      text: "Do you want to delete your account? We will send you a confirmation one-time-password (otp) to your email.",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Yes, Send confirmation code",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setUserDeleteLoading(true);
        try {
          const response = await deleteAccountOTPAsync();
          if (response.status === 200) {
            setUserDeleteLoading(false);
            setOtpModal(true);
          }
        } catch (error: any) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
        }
      }
    });
  };

  const handleUserImg = (e: ChangeEvent<HTMLInputElement>) => {
    // Reference to the DOM input element
    const { files } = e.target;
    // Ensure that you have a file before attempting to read it
    if (files && files[0] && files[0].size <= UPLOAD_SIZE_LIMIT * 1024) {
      setUsrImg(URL.createObjectURL(files[0]));
      handleModalSelection("photo");
    } else {
      dispatch(
        handleSnackbarOpen({
          message: "File size is too big! Image size should be less than 1MB",
          severity: "warning",
        }),
      );
      setUsrImg("");
      handleClose();
    }
    // Clear the event target value to give the possibility to upload the same image:
    e.target.value = "";
  };

  // saves the image from child component to this i.e. parent component
  const handleImage = (img: string) => {
    setUsrImg(img);
    handleClose();
  };

  // handles image deleting
  const handleDelete = () => {
    setUsrImg("");
    handleClose();
  };

  return (
    <Grid container className="third-grid">
      <Grid container className="profile-details-grid">
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography
            fontFamily="Inter"
            fontSize={13}
            fontWeight={800}
            textAlign="left"
            color="text.primary"
            marginBottom={2}
          >
            Profile Image
          </Typography>
          <Typography
            fontFamily="Inter"
            fontSize={13}
            fontWeight={600}
            textAlign="left"
            color="text.secondary"
            marginBottom={1}
          >
            Upload an image
            <br />
            of type .png, .jpg, .jpeg
          </Typography>
        </Grid>
        <Grid container item lg={6} md={4} sm={4} xs={4}>
          <Grid container className="profile-form">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="upload-img-btn-wrapper">
                {usrImg ? (
                  <button
                    type="button"
                    className="btn"
                    onClick={() => handleModalSelection("photo")}
                  >
                    <EditRounded
                      className="add_new-circle_icon"
                      titleAccess="Change logo"
                    />
                    <img src={usrImg} alt="user" className="user_img" />
                  </button>
                ) : (
                  <button type="button" className="btn">
                    <CameraAlt color="inherit" />
                    <Typography
                      fontSize={12}
                      color="text.disabled"
                      fontFamily="Inter"
                    >
                      Click to browse or drag & drop your files
                    </Typography>
                    <input type="file" name="myfile" onChange={handleUserImg} />
                  </button>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="third-grid">
        <Grid item lg={4} md={12} sm={12} xs={12}>
          <Typography className="user-profile-text" color="text.primary">
            qBraid Profile
          </Typography>
          <Typography
            mb={2}
            color="text.secondary"
            fontSize={13}
            fontWeight={600}
            width={{ lg: 180, md: "max-content" }}
          >
            Update your username, affiliation, and more.
          </Typography>
        </Grid>
        <Grid container item lg={6} md={9} sm={9} xs={12}>
          <Grid container className="profile-form">
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography color="text.secondary" fontSize={13} fontWeight={600}>
                Display name:
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={9}
              sm={9}
              xs={12}
              marginBottom={nameError ? 2 : 0}
            >
              <TextField
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  "&>.MuiInputBase-root": {
                    color: theme.palette.text.primary,
                  },
                })}
                className="textfield"
                label={inputData.userName === "" ? "New username" : ""}
                placeholder="ex. user007, markov_3..."
                error={Boolean(nameError)}
                helperText={nameError}
                color="secondary"
                name="userName"
                value={inputData.userName}
                onChange={handleChange}
              />{" "}
            </Grid>
          </Grid>
          <Grid container className="profile-form">
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography color="text.secondary" fontSize={13} fontWeight={600}>
                First name:
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={9}
              sm={9}
              xs={12}
              marginBottom={nameError && "1em"}
            >
              <TextField
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  "&>.MuiInputBase-root": {
                    color: theme.palette.text.primary,
                  },
                })}
                className="textfield"
                label={inputData.firstName === "" ? "First Name" : ""}
                placeholder="ex. John, Peter..."
                color="secondary"
                name="firstName"
                value={inputData.firstName}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container className="profile-form">
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography color="text.secondary" fontSize={13} fontWeight={600}>
                Last name:
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={9}
              sm={9}
              xs={12}
              marginBottom={nameError && "1em"}
            >
              <TextField
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  "&>.MuiInputBase-root": {
                    color: theme.palette.text.primary,
                  },
                })}
                className="textfield"
                label={inputData.lastName === "" ? "Last Name" : ""}
                placeholder="ex. Constantine, Parker..."
                color="secondary"
                name="lastName"
                value={inputData.lastName}
                onChange={handleChange}
              />{" "}
            </Grid>
          </Grid>
          <Grid container className="profile-form">
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography color="text.secondary" fontSize={13} fontWeight={600}>
                Affiliation:
              </Typography>
            </Grid>
            <Grid item lg={6} md={9} sm={9} xs={12}>
              <TextField
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  "&>.MuiInputBase-root": {
                    color: theme.palette.text.primary,
                  },
                })}
                className="textfield"
                type="text"
                inputProps={{ maxLength: 50 }}
                color="secondary"
                placeholder="Name of organization or institution"
                name="organization"
                value={inputData.organization}
                onChange={handleChange}
              />{" "}
            </Grid>
          </Grid>
          {/* <Grid container className="profile-form">
						<Grid item lg={3} md={3} sm={3} xs={12}>
							<Typography className="user-profile-subtext">
								Interests:
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<TextField
                            sx={theme => ({ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary})}
								className="textfield"
								type="text"
								inputProps={{ maxLength: 100 }}
								color="secondary"
								placeholder="quantum computing, machine learning, etc."
								name="interests"
								value={inputData?.interests}
								onChange={handleChange}
							/>
						</Grid>
					</Grid> */}
          <Grid container className="profile-form">
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography color="text.secondary" fontSize={13} fontWeight={600}>
                About me:
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  "&>.MuiInputBase-root": {
                    color: theme.palette.text.primary,
                  },
                })}
                type="text"
                inputProps={{ maxLength: 150 }}
                color="secondary"
                // multiline={true} can we fix the css to make this multiline?
                placeholder="Introduce yourself to the community"
                name="bio"
                value={inputData.bio}
                className="textfield"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container className="profile-form">
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography color="text.secondary" fontSize={13} fontWeight={600}>
                Education:
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  "&>.MuiInputBase-root": {
                    color: theme.palette.text.primary,
                  },
                })}
                className="textfield"
                type="text"
                inputProps={{ maxLength: 100 }}
                color="secondary"
                // multiline={true} can we fix the css to make this multiline?
                placeholder="Where did you study?"
                name="levelOfEducation"
                value={inputData.levelOfEducation}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid container className="third-grid">
        <Grid item lg={4} md={12} sm={12} xs={12} className="grid-text">
          <Typography className="user-profile-text" color="text.primary">
            Links
          </Typography>
          <Typography
            mb={2}
            color="text.secondary"
            fontSize={13}
            fontWeight={600}
            width={{ lg: 180, xs: "max-content" }}
          >
            How can others connect with you?
          </Typography>
        </Grid>
        <Grid item lg={6} md={9} sm={9} xs={9}>
          <Grid container className="profile-form">
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography color="text.secondary" fontSize={13} fontWeight={600}>
                Github URL:
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              marginBottom={
                errors?.githubUrl && inputData.githubUrl !== "" ? 2 : 0
              }
            >
              <TextField
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  "&>.MuiInputBase-root": {
                    color: theme.palette.text.primary,
                  },
                })}
                error={Boolean(errors?.githubUrl && inputData.githubUrl !== "")}
                helperText={
                  errors?.githubUrl &&
                  inputData.githubUrl !== "" &&
                  "Please enter a valid GitHub URL"
                }
                id="outlined-error-helper-text"
                defaultValue="github.com/qbraid"
                className="textfield"
                color={errors?.githubUrl ? "secondary" : "success"}
                placeholder="https://github.com/username"
                name="githubUrl"
                value={inputData.githubUrl}
                onChange={(e) => {
                  urlValidationSchema
                    .isValid({ githubUrl: e.target.value })
                    .then((isValid) => {
                      setErrors((ev) => ({
                        ...ev,
                        githubUrl: !isValid,
                      }));
                    });
                  handleChange(e);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className="profile-form">
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography color="text.secondary" fontSize={13} fontWeight={600}>
                Twitter:
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              sx={{
                marginBottom:
                  errors?.twitterUrl && inputData.twitterUrl !== "" ? 2 : 0,
              }}
            >
              <TextField
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  "&>.MuiInputBase-root": {
                    color: theme.palette.text.primary,
                  },
                })}
                error={Boolean(
                  errors?.twitterUrl && inputData.twitterUrl !== "",
                )}
                helperText={
                  errors?.twitterUrl &&
                  inputData.twitterUrl !== "" &&
                  "Please enter a valid Twitter URL"
                }
                id="outlined-error-helper-text"
                className="textfield"
                color={
                  Boolean(errors?.twitterUrl) === false
                    ? "success"
                    : "secondary"
                }
                placeholder="https://twitter.com/username"
                name="twitterUrl"
                value={inputData.twitterUrl}
                onChange={(e) => {
                  urlValidationSchema
                    .isValid({ twitterUrl: e.target.value })
                    .then((isValid) => {
                      setErrors((ev) => ({
                        ...ev,
                        twitterUrl: !isValid,
                      }));
                    });
                  handleChange(e);
                }}
              />{" "}
            </Grid>
          </Grid>
          <Grid container className="profile-form"></Grid>
          <Grid container className="profile-form">
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography color="text.secondary" fontSize={13} fontWeight={600}>
                Google Scholar:
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              marginBottom={
                errors?.googleScholar && inputData.googleScholar !== ""
                  ? "1em"
                  : "0em"
              }
            >
              <TextField
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  "&>.MuiInputBase-root": {
                    color: theme.palette.text.primary,
                  },
                })}
                error={Boolean(
                  errors?.googleScholar && inputData.googleScholar !== "",
                )}
                helperText={
                  errors?.googleScholar &&
                  inputData.googleScholar !== "" &&
                  "Please enter a valid Google Scholar URL"
                }
                id="outlined-error-helper-text"
                className="textfield"
                color={
                  errors?.googleScholar === false ? "success" : "secondary"
                }
                placeholder="https://google.com/scholarName"
                name="googleScholar"
                value={inputData.googleScholar}
                onChange={(e) => {
                  urlValidationSchema
                    .isValid({
                      googleScholarUrl: e.target.value,
                    })
                    .then((isValid) => {
                      setErrors((ev) => ({
                        ...ev,
                        googleScholar: !isValid,
                      }));
                    });
                  handleChange(e);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className="profile-form">
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography color="text.secondary" fontSize={13} fontWeight={600}>
                LinkedIn:
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              marginBottom={
                errors?.linkedIn && inputData.linkedIn !== "" ? "1em" : "0em"
              }
            >
              <TextField
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  "&>.MuiInputBase-root": {
                    color: theme.palette.text.primary,
                  },
                })}
                error={Boolean(errors?.linkedIn && inputData.linkedIn !== "")}
                helperText={
                  errors?.linkedIn &&
                  inputData.linkedIn !== "" &&
                  "Please enter a valid LinkedIn URL"
                }
                id="outlined-error-helper-text"
                defaultValue=""
                className="textfield"
                color={errors?.linkedIn === false ? "success" : "secondary"}
                placeholder="https://www.linkedin.com/us/username"
                name="linkedIn"
                value={inputData.linkedIn}
                onChange={(e) => {
                  urlValidationSchema
                    .isValid({
                      linkedInUrl: e.target.value,
                    })
                    .then((isValid) => {
                      setErrors((ev) => ({
                        ...ev,
                        linkedIn: !isValid,
                      }));
                    });
                  handleChange(e);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          <Button
            variant="outlined"
            sx={{ borderRadius: "100vw", textTransform: "unset" }}
            color="success"
            startIcon={<ManageAccounts />}
            disabled={isSubmitting}
            onClick={updateProfile}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{ borderRadius: "100vw", textTransform: "unset" }}
            startIcon={<AdminPanelSettings />}
            onClick={() => handleModalSelection("account")}
          >
            Account ownership and control
          </Button>
        </Grid>
      </Grid>

      <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen}>
        {selectedModal === "photo" ? (
          <ImageEditor
            photo={usrImg}
            setPhoto={handleImage}
            deleteAndReset={handleDelete}
          />
        ) : selectedModal === "account" ? (
          <>
            <Stack flexDirection="row" justifyContent="flex-end" pt={2} pr={2}>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Stack>
            <DialogTitle>Account ownership and control</DialogTitle>
            <DialogContent sx={{ pb: 8 }}>
              <Stack overflow="hidden" gap={1}>
                <Button
                  variant="outlined"
                  endIcon={
                    <ArrowForwardIos
                      color="inherit"
                      sx={{ color: "text.primary" }}
                    />
                  }
                  sx={{
                    textTransform: "unset",
                  }}
                  onClick={() => handleModalSelection("delete")}
                >
                  <Stack alignItems="flex-start" flex={1}>
                    <Typography
                      fontFamily="Inter"
                      fontSize={18}
                      fontWeight={600}
                      color="text.primary"
                      textAlign="left"
                    >
                      Delete Account
                    </Typography>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={400}
                      color="text.secondary"
                      textAlign="left"
                      pr={2}
                      sx={{ opacity: 0.8 }}
                    >
                      Permanently delete your account, activities and progress
                    </Typography>
                  </Stack>
                </Button>
                <Button
                  variant="outlined"
                  endIcon={
                    <ArrowForwardIos
                      color="inherit"
                      sx={{ color: "text.primary" }}
                    />
                  }
                  fullWidth
                  sx={{
                    textTransform: "unset",
                  }}
                  onClick={() => handleModalSelection("change_password")}
                >
                  <Stack alignItems="flex-start" flex={1}>
                    <Typography
                      fontFamily="Inter"
                      fontSize={18}
                      fontWeight={600}
                      color="text.primary"
                      textAlign="left"
                    >
                      Change password
                    </Typography>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={400}
                      color="text.secondary"
                      textAlign="left"
                      pr={2}
                      sx={{ opacity: 0.8 }}
                    >
                      Change your account password
                    </Typography>
                  </Stack>
                </Button>
              </Stack>
            </DialogContent>
          </>
        ) : selectedModal === "change_password" ? (
          <>
            <Stack pt={2} pr={2} pl={2} gap={4}>
              <Stack flexDirection="row" justifyContent="space-between">
                <IconButton
                  disableFocusRipple
                  onClick={() => handleModalSelection("account")}
                >
                  <ArrowBackIos />
                </IconButton>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Stack>
              <Stack gap={0.5} px={1}>
                <Typography fontSize={16}>
                  {userDetails?.email} • {DOMAIN_CONFIG_OBJ.domainName}
                </Typography>
                <DialogTitle fontWeight={600} sx={{ p: 0 }}>
                  Change Password
                </DialogTitle>
                <Typography fontSize={16}>
                  Your password must be at least eight characters and should
                  include a combination of numbers, letters (both upper and
                  lower case) and special characters ( @$!%*#?& ).
                </Typography>
              </Stack>
            </Stack>
            <form
              spellCheck="false"
              aria-describedby="update-passowrd"
              onSubmit={formik.handleSubmit}
            >
              <DialogContent>
                <Stack gap={2} mt={2}>
                  <TextField
                    sx={(theme) => ({
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                      "&>.MuiInputBase-root": {
                        color: theme.palette.text.primary,
                      },
                    })}
                    fullWidth
                    placeholder="Current Password"
                    name="currentPassword"
                    type={showCurrentPassword ? "text" : "password"}
                    required
                    value={formik.values.currentPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.currentPassword &&
                      Boolean(formik.errors.currentPassword)
                    }
                    helperText={
                      formik.touched.currentPassword &&
                      formik.errors.currentPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showCurrentPassword ? (
                            <VisibilityOff
                              onClick={showCurrentPasswordHandler}
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Visibility
                              onClick={showCurrentPasswordHandler}
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={(theme) => ({
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                      "&>.MuiInputBase-root": {
                        color: theme.palette.text.primary,
                      },
                    })}
                    fullWidth
                    placeholder="New Password"
                    name="newPassword"
                    type={showNewPassword ? "text" : "password"}
                    required
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.newPassword &&
                      Boolean(formik.errors.newPassword)
                    }
                    helperText={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showNewPassword ? (
                            <VisibilityOff
                              onClick={showNewPasswordHandler}
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Visibility
                              onClick={showNewPasswordHandler}
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={(theme) => ({
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                      "&>.MuiInputBase-root": {
                        color: theme.palette.text.primary,
                      },
                    })}
                    fullWidth
                    placeholder="Retype Password"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showConfirmPassword ? (
                            <VisibilityOff
                              onClick={showConfirmPasswordHandler}
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Visibility
                              onClick={showConfirmPasswordHandler}
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </DialogContent>
              <DialogActions sx={{ px: 3, py: 3 }}>
                <Button
                  variant="qbookStyledBtn"
                  fullWidth
                  size="large"
                  type="submit"
                  sx={{ height: 50 }}
                  disabled={!formik.dirty}
                >
                  Change Password
                </Button>
              </DialogActions>
            </form>
          </>
        ) : (
          <>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              pt={2}
              pr={2}
              pl={2}
            >
              <IconButton
                disableFocusRipple
                onClick={() => handleModalSelection("account")}
              >
                <ArrowBackIos />
              </IconButton>
              <DialogTitle
                sx={{
                  display: { xs: "none", sm: "inline-block" },
                }}
              >
                Delete account
              </DialogTitle>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Stack>

            <DialogContent>
              <Stack alignItems="center" gap={4} pb={4}>
                <Avatar
                  src={userDetails?.personalInformation.profilePhoto}
                  alt="profile-image"
                  sx={{
                    boxShadow: "0rem 0.3125rem 0.625rem 0rem rgb(0 0 0 / 12%)",
                    width: "7rem",
                    height: "7rem",
                    fontSize: "3rem",
                    textTransform: "uppercase",
                  }}
                >
                  {userDetails?.userName?.split(" ")[0][0]}
                </Avatar>
                <Button
                  sx={{ textTransform: "unset" }}
                  onClick={handleDeleteAccountConfirmation}
                >
                  <Stack flexDirection="row" gap={4} alignItems="center">
                    <Warning color="warning" sx={{ width: 48, height: 48 }} />
                    <Stack gap={1} alignItems="flex-start">
                      <Typography
                        color="text.primary"
                        fontSize={20}
                        fontWeight={600}
                        textAlign="left"
                      >
                        Deleting your account is permanent
                      </Typography>
                      <Typography
                        color="text.secondary"
                        fontSize={14}
                        fontWeight={400}
                        textAlign="left"
                      >
                        Your organizations, code, files, subscription, credits
                        and invoices will be permanently deleted and the action
                        is irreversable.
                      </Typography>
                    </Stack>
                  </Stack>
                </Button>
              </Stack>
            </DialogContent>
          </>
        )}
      </Dialog>
      <OtpSuccessDialog
        open={otpModal}
        handleClose={handleCloseOtp}
        verifyUserOTP={verifyUserOTP}
        resendUserOTP={resendUserOTP}
        otpError={otpError}
        userDeleteLoading={userDeleteLoading}
        handleFocus={() => {
          setOtpError(null);
        }}
      />
    </Grid>
  );
};

export default ProfileDetails;
