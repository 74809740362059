/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../reduxStore/hooks";
import {
  addCourseIdInToRefreshingCourses,
  setSelectedCourse,
  setShowGrader,
} from "../../../../../../reduxStore/features/adminCourseSlice";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { BorderLinearProgress } from "../../../../../components/styledComponents/ProgressBar";
import { loadSingleCourseDataAction } from "../../../../../../reduxStore/middleware/adminCourseMiddleware";

type Props = {
  course: any;
  selectedView?: boolean;
};
interface User {
  role: string | null;
  progress: number;
}
const calculateProgress = (students: User[], totalPoints: number): string => {
  const accumulatedProgress = students.reduce(
    (acc, student) => acc + student.progress,
    0,
  );
  const expectedProgress = students.length * totalPoints;
  return ((accumulatedProgress / expectedProgress) * 100).toFixed(2);
};

const AdminCourseCard: React.FC<Props> = React.memo(
  ({ course, selectedView = false }) => {
    const [graderLoading, setGraderLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { selectedCourse, refreshingCourses } = useAppSelector(
      (state: any) => state.adminCourse,
    );
    const [overAllCourseProgress, setOverallCourseProgress] = useState("0.0");

    const handleCourseSelection = useCallback(() => {
      dispatch(setSelectedCourse({ courseId: course._id }));
    }, [dispatch, course._id]);

    const handleShowGrader = useCallback(async () => {
      setGraderLoading(true);
      try {
        dispatch(addCourseIdInToRefreshingCourses({ courseId: course._id }));
        await dispatch(loadSingleCourseDataAction({ courseId: course._id }));
        setGraderLoading(false);
        dispatch(setShowGrader(true));
      } catch (err) {
        setGraderLoading(false);
        console.log("[!] Error : course grader ", err);
      }
    }, [dispatch, course._id]);

    const students = useMemo(() => {
      const userList = selectedView
        ? selectedCourse?.userList
        : course.userList;
      return userList?.filter((user: User) => user?.role === "Student") || [];
    }, [selectedView, selectedCourse, course]);

    useEffect(() => {
      const progressPercentage = calculateProgress(
        students,
        selectedView
          ? selectedCourse.courseProgressPoints
          : course.courseProgressPoints,
      );
      setOverallCourseProgress(progressPercentage);
    }, [students, selectedView, selectedCourse, course]);

    const isSelected = useMemo(
      () => selectedCourse._id === course._id && !selectedView,
      [selectedView, course, selectedCourse],
    );
    const courseUnderProcessing = useMemo(
      () => refreshingCourses.length > 0,
      [refreshingCourses],
    );
    return (
      <Grid
        item
        container
        xs={12}
        sx={{
          boxShadow: isSelected
            ? "0px 4px 4px 0px #00000040"
            : "var(--admin-course-card-shadow)",
          borderRadius: "5px",
          border: isSelected ? "1px solid var(--accent-clr2)" : "",
          width: "100%",
          minHeight: "217px",
          maxHeight: "217px",
          padding: { lg: "10px 25px", md: "10px", sm: "5px", xs: "5px" },
          // cursor: "pointer",
          marginRight: "5px",
        }}
        onClick={() => {
          handleCourseSelection();
        }}
        cy-data="admin-course-card"
      >
        <Grid
          item
          container
          xs={12}
          sx={{
            minHeight: "5px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Typography
              sx={{
                fontSize: selectedView ? "33px" : "17px",
                fontWeight: 900,
                lineHeight: selectedView ? "45px" : "23px",
                letterSpacing: "0.05em",
                textAlign: "left",
                color: "var(--accent-clr3)",
                width: "100%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              cy-data="admin-course-card-name"
            >
              {!selectedView ? course.courseName : selectedCourse.courseName}
            </Typography>
          </Grid>
          {selectedView && (
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-end",
                  lg: "flex-end",
                },
              }}
              cy-data="admin-course-card-deployed-org"
            >
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 400,
                  lineHeight: "17x",
                  letterSpacing: "0.05em",
                  textAlign: "left",
                  color: "var(--text-primary-clr1)",
                  paddingRight: "10px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {`Course deployed in: `}
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 700,
                  lineHeight: "17x",
                  letterSpacing: "0.05em",
                  textAlign: "left",
                  color: "var(--text-primary-clr1)",
                  width: "50%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {`${
                  selectedCourse?.orgData?.orgName
                    ? `${selectedCourse?.orgData?.orgName} (${selectedCourse?.visibility})`
                    : `${selectedCourse?.visibility}`
                }`}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 900,
              lineHeight: "17x",
              letterSpacing: "0.05em",
              textAlign: "left",
              color: "#AFAFAF",
            }}
            cy-data="admin-course-card-description"
          >
            {!selectedView
              ? course.courseDescription
              : selectedCourse.courseDescription}
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={12} md={12} lg={selectedView ? 8 : 12}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 900,
                lineHeight: "21x",
                letterSpacing: "0.05em",
                textAlign: "left",
                color: "#AFAFAF",
              }}
              cy-data="admin-course-card-progress-label"
            >
              Overall Course Progress
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 900,
                lineHeight: "21x",
                letterSpacing: "0.05em",
                textAlign: "left",
                color: "#AFAFAF",
              }}
            >
              {overAllCourseProgress}
              /100
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BorderLinearProgress
              variant="determinate"
              value={parseFloat(overAllCourseProgress)}
              cy-data="admin-course-card-progress-bar"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={selectedView ? 4 : 12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "5px",
            flexWrap: "wrap",
          }}
        >
          {selectedView && (
            <Typography
              sx={{
                height: 40,
                minWidth: "67px",
                textTransform: "capitalize",
                fontSize: 14,
                fontWeight: 400,
                border: "none",
                color: "black",
                borderRadius: "4px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#FF8D8DC9",
              }}
              cy-data="admin-course-card-admin-indicator"
            >
              Admin
            </Typography>
          )}
          <Button
            sx={{
              height: 40,
              minWidth: "167px",
              textTransform: "capitalize",
              fontSize: 14,
              fontWeight: 400,
              border: "none",
              color: "white",
              backgroundImage:
                "linear-gradient(110deg,var(--accent-clr1) 50%,var(--accent-clr2) 50%)",
              backgroundSize: "222%",
              backgroundPosition: "right",
              transition:
                "background-position 200ms linear, letter-spacing 200ms linear",
              ":hover": {
                backgroundPosition: "left",
              },
            }}
            endIcon={
              graderLoading ? (
                <CircularProgress
                  sx={{ color: "white" }}
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <ArrowForwardIosIcon />
              )
            }
            onClick={() => handleShowGrader()}
            cy-data="admin-course-card-open-grader-btn"
            disabled={courseUnderProcessing}
          >
            Open Grader
          </Button>
        </Grid>
      </Grid>
    );
  },
);

export default AdminCourseCard;
