/**
 * @fileoverviewOtpSuccessDialog component
 * @fileoverviewThis file contains the design and functionality of the OtpSuccessDialog component
 * @todo
 * Add tests for the OtpSuccessDialog component
 * @copyright (C) 2024 qBraid Development Team
 */
import * as React from "react";
import {
  Typography,
  Stack,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { OtpErrorTypes } from "../../../types";

type PropTypes = {
  userDeleteLoading: boolean;
  open: boolean;
  handleClose: () => void;
  verifyUserOTP: (value: string) => void;
  resendUserOTP: () => void;
  otpError: OtpErrorTypes;
  handleFocus: () => void;
};

const OtpSuccessDialog = ({
  userDeleteLoading,
  open,
  handleClose,
  verifyUserOTP,
  resendUserOTP,
  otpError,
  handleFocus,
}: PropTypes) => {
  const [otp, setOtp] = React.useState("");

  return (
    <Dialog
      PaperProps={{ sx: { padding: 3 } }}
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 19,
        }}
      >
        <CheckCircleOutlineRoundedIcon sx={{ fontSize: 100 }} color="success" />
      </div>
      <DialogTitle style={{ textAlign: "center", fontSize: 30 }}>
        Please enter OTP.
      </DialogTitle>
      <DialogContent>
        <Stack gap={1}>
          <Typography textAlign="center" fontSize={14} color="text.secondary">
            OTP has been sent to your email.
          </Typography>
          <TextField
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              "&>.MuiInputBase-root": {
                color: theme.palette.text.primary,
              },
            })}
            color="secondary"
            autoFocus
            margin="dense"
            id="name"
            label="Enter OTP"
            type="otp"
            fullWidth
            variant="outlined"
            onFocus={handleFocus}
            onChange={(e) => {
              setOtp(e.target.value);
            }}
            error={otpError?.error}
            helperText={otpError?.helperText}
          />
        </Stack>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-evenly" }}>
        <Button
          variant="contained"
          color="error"
          disabled={userDeleteLoading}
          onClick={() => {
            verifyUserOTP(otp);
          }}
        >
          {userDeleteLoading ? "Loading....." : "Delete Account"}
        </Button>
        <Button variant="contained" color="secondary" onClick={resendUserOTP}>
          Resend OTP
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OtpSuccessDialog;
