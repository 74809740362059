import { axiosClient, domain } from "../../utils/axiosInstance";

export const getCourseAsync = (courseId: string, fileId: string) => {
  const response = axiosClient().post(
    "/public/get-course-file/" + fileId,
    {
      courseId: courseId,
    },
    {
      params: { deployedTo: domain },
    },
  );
  return response;
};

export const loadPublicCourses = (page: number, resultsPerPage: number) => {
  const response = axiosClient().get(`/public/get-courses/${page}`, {
    params: { deployedTo: domain, resultsPerPage: resultsPerPage },
  });
  return response;
};

export const registerUserInToCourse = (courseId: string) => {
  try {
    const response = axiosClient().post(`/register-for-a-course/${courseId}`, {
      params: { deployedTo: domain },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const loadUserRegisteredCourses = () => {
  const response = axiosClient().get(`/get-user-courses`);
  return response;
};
