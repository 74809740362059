import React, { useMemo } from "react";
import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  LinearProgress,
  Grid,
} from "@mui/material";

interface DownloadButtonProps {
  url: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = React.memo(({ url }) => (
  <a
    target="_blank"
    href={url}
    style={{
      padding: ".5em",
      textDecoration: "none",
      background: "blueviolet",
      borderRadius: "5px",
      color: "#ffffff",
    }}
    rel="noreferrer"
    cy-data="admin-frq-accordion-submitted-docs-viewer-download-btn"
  >
    Download
  </a>
));

interface ProgressBarProps {
  questionThemeColor: "blue" | "pink" | string;
}

const ProgressBar: React.FC<ProgressBarProps> = React.memo(
  ({ questionThemeColor }) => {
    const progressStyles = useMemo(
      () => ({
        backgroundColor:
          questionThemeColor === "blue"
            ? "#284bc080"
            : questionThemeColor === "pink"
              ? "#c0288878"
              : "#7a2dc77a",
        "& .MuiLinearProgress-bar": {
          backgroundColor:
            questionThemeColor === "blue"
              ? "#284bc0"
              : questionThemeColor === "pink"
                ? "#c02888"
                : "#7A2DC7",
        },
      }),
      [questionThemeColor],
    );

    return <LinearProgress sx={progressStyles} />;
  },
);

interface SelectedDoc {
  s3Url: string;
  documentDescription: string;
}

interface DocumentViewerProps {
  selectedDoc: SelectedDoc;
  getImage: (url: string) => string;
}

const DocumentViewer: React.FC<DocumentViewerProps> = React.memo(
  ({ selectedDoc, getImage }) => (
    <Grid
      container
      width={500}
      maxHeight={700}
      gap={2}
      cy-data="admin-frq-accordion-submitted-docs-viewer"
    >
      <Grid item xs={12} boxShadow={1}>
        <img
          src={getImage(selectedDoc.s3Url)}
          alt="dialog-img-preview"
          height="100%"
          width="100%"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {selectedDoc.documentDescription}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          minHeight: "50px",
        }}
      >
        <DownloadButton url={selectedDoc.s3Url} />
      </Grid>
    </Grid>
  ),
);

interface OptimizedDialogProps {
  showDialog: boolean;
  setShowDialog: (show: boolean) => void;
  isUpdating: boolean;
  questionThemeColor: "blue" | "pink" | string;
  selectedDoc: SelectedDoc;
  getImage: (url: string) => string;
}

const OptimizedAccordionDialog: React.FC<OptimizedDialogProps> = ({
  showDialog,
  setShowDialog,
  isUpdating,
  questionThemeColor,
  selectedDoc,
  getImage,
}) => (
  <Dialog
    open={showDialog}
    onClose={() => {
      !isUpdating && setShowDialog(false);
    }}
  >
    <DialogContent>
      {isUpdating ? (
        <Stack flexDirection="column" gap={1}>
          <Typography fontSize={16} color="text.primary">
            Please wait, Saving your response
          </Typography>
          <ProgressBar questionThemeColor={questionThemeColor} />
        </Stack>
      ) : (
        <DocumentViewer selectedDoc={selectedDoc} getImage={getImage} />
      )}
    </DialogContent>
  </Dialog>
);

export default React.memo(OptimizedAccordionDialog);
