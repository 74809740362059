/**
 * @fileOverview This file handles creation of dynamic ids for front end operations
 * also the latex field generation for qbook
 * @module utils
 */

import "katex/dist/katex.min.css";
import TeX from "@matejmazur/react-katex";

function generateCustomId(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const parseMixedText = (text: string) => {
  // parses text that includes normal text interspersed with LaTeX in block delimited by '$''
  const segments: Array<any> = [];
  // This is a patch to handle the case where the text is an object from older questions
  if (typeof text === "object") {
    // Remove the type field from the object
    if ("type" in text) {
      delete (text as { type?: any }).type;
    }
    // Convert the values of the object to a string
    text = Object.values(text).join("");
  }
  text?.split("$").forEach((segment, index) => {
    if (index % 2 === 0) {
      if (segment) {
        segments.push(
          <span key={index} cy-data="qbook-notebook-quiz-question-parsed-text">
            {segment}
          </span>,
        );
      }
    } else {
      segments.push(
        <span key={index} cy-data="qbook-notebook-quiz-question-parsed-latex">
          <TeX key={index} math={segment} />
        </span>,
      );
    }
  });
  return [segments];
};

export { parseMixedText, generateCustomId };
